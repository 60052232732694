import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LangConstant } from "const";
import { Sidebar } from "./components";
import { useBranchDetails } from "hooks";

const MainLayout = ({ children }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);
  const { checkHealth } = useBranchDetails();

  const [licenseDayRemaining, setLicenseDayRemaining] = useState(99999);
  const [licenseExpiryTime, setLicenseExpiryTime] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  const useStyles = makeStyles(theme => ({
    layoutContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },

    sidebarGrid: {
      width: "300px",
      flexShrink: 0,
    },

    mainGrid: {
      width: "100%",
      height: "100%",
    },

    mainContainer: {
      height: licenseDayRemaining > 7 ? "calc(100vh - 40px)" : "calc(100vh - 100px)",
      width: "calc(100vw - 300px)",
      backgroundColor: "#f1f1f1",
      overflow: "auto",
    },

    childrenContainer: {
      padding: "40px 25px",
      height: "100%",
      width: "100%",
      minWidth: "800px",
    },

    footer: {
      bottom: 0,
      left: 0,
      width: "100%",
      lineHeight: "20px",
      textAlign: "center",
      padding: "10px 0",
      height: "40px",
      backgroundColor: "#f1f1f1",
    },

    helpCenter: {
      textAlign: "center",
      position: "fixed",
      bottom: 46,
      right: 46,
    },

    helpCenterIcon: {
      cursor: "pointer",
      color: theme.palette.common.white,
    },

    helpCenterText: {
      lineHeight: "16px",
    },

    warningLicense: {
      height: "60px",
      marginBottom: "5px",
      backgroundColor: licenseDayRemaining > 3 ? "#FF7700" : "red",
      borderRadius: 4,
      color: "#ffffff",
      display: licenseDayRemaining > 7 ? "none" : "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (checkHealth && checkHealth.data) {
      if (checkHealth.data.license_expiry_time) {
        let expiry_time = new Date(checkHealth.data.license_expiry_time).getTime();
        let current_time = Date.now();
        if (expiry_time > current_time) {
          setLicenseDayRemaining((expiry_time - current_time) / (1000 * 60 * 60 * 24));
          setLicenseExpiryTime(
            new Date(expiry_time).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" }),
          );
        } else {
          setIsExpired(true);
        }
      }
    }
  }, [checkHealth]);

  return (
    <Box>
      <Box className={classes.warningLicense}>
        <h2>
          {isExpired ? (
            <>Máy chủ đã hết hạn vào ngày {licenseExpiryTime}. Vui lòng liên hệ +84-24-3204-5456 để gia hạn sử dụng.</>
          ) : (
            <>Máy chủ sẽ hết hạn vào ngày {licenseExpiryTime}. Vui lòng liên hệ +84-24-3204-5456 để gia hạn sử dụng.</>
          )}
        </h2>
      </Box>

      <Box className={classes.layoutContainer}>
        <Box className={classes.sidebarGrid}>
          <Sidebar />
        </Box>
        <Box className={classes.mainGrid}>
          <Box className={classes.mainContainer}>
            <Box className={classes.childrenContainer}>{children}</Box>
          </Box>
          <Typography className={clsx(classes.footer, "regular-sm-txt")}>
            {getLabel(LangConstant.TXT_APP_COPYRIGHT)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
