import { ApiConstant, KeyConstant } from "const";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";
import { Upload } from "tus-js-client";

export default function useTusUpload() {
  const tusDomain = StorageUtils.getStoreData(KeyConstant.KEY_TUS_DOMAIN);
  const accessToken = StorageUtils.getStoreData(KeyConstant.KEY_ACCESS_TOKEN);

  const uploadAvatar = (attachmentId, accountId, file, onSuccess) => {
    return new Promise((resolve, reject) => {
      try {
        const endpointTus = "https://" + tusDomain + "/files/" + attachmentId;
        const tusHeaders = {
          type: 0,
          authorization: `Bearer ${accessToken}`,
          account_id: accountId,
          "Access-Control-Allow-Origin": "*",
        };

        const extension = file.type.split("/")[1];

        const tusOptions = {
          endpoint: endpointTus,
          overridePatchMethod: true, // => true: using POST method
          retryDelays: [0, 1000, 3000, 5000],
          headers: tusHeaders,
          metadata: {
            filename: `${attachmentId}.${extension}`,
            filetype: file.type,
          },
          removeFingerprintOnSuccess: true,
          storeFingerprintForResuming: false,

          onError(error) {
            console.log(error);
            SystemUtils.appendNotification("Tải lên thất bại", "error");
          },
          onProgress(bytesUploaded, bytesTotal) {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log(bytesUploaded, bytesTotal, `${percentage}%`);
          },
          onSuccess() {
            SystemUtils.appendNotification("Tải lên thành công", "success");
            onSuccess(attachmentId);
          },
        };

        const upload = new Upload(file, tusOptions);
        upload.start();
      } catch (error) {
        console.log("tus upload error: ", error);
      }
    });
  };

  return { uploadAvatar };
}
