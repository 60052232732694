import React, { Fragment } from "react";
import { useHandleLocale, useHandleSnackbar } from "hooks";
import { CircleLoading } from "components";
import { Router as ReactRouter, Switch, Redirect, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import { createBrowserHistory } from "history";
import { KeyConstant, LangConstant, PathConstant } from "const";
import NotFound from "pages/NotFound";
import Qr from "pages/Qr";
import Login from "pages/Login";
import SmartOTP from "pages/Login/SmartOTP";
import FirstLogin from "pages/Login/FirstLogin";
import BranchManagement from "pages/BranchManagement";
import CompanyManagement from "pages/CompanyManagement";
import UserManagement from "pages/UserManagement";
import License from "pages/License";
import SystemTracking from "pages/SystemTracking";
import Settings from "pages/Settings";
import Monitoring from "pages/Monitoring";
import PositionManagement from "pages/PositionManagement";
import MediaManagement from "pages/MediaManagement";
import Statistic from "pages/Statistic";
import { AuthUtils, StorageUtils } from "utils";
import { MainLayout } from "./components";
import "./language";
import "./theme/styles";

const App = () => {
  useHandleSnackbar();
  useHandleLocale();

  return (
    <ReactRouter history={browserHistory}>
      <Routes />
      <CircleLoading />
    </ReactRouter>
  );
};

export default App;

const browserHistory = createBrowserHistory();

const Routes = () => {
  const location = useLocation();
  const { t: getLabel } = useTranslation(LangConstant.NS_COMMON);

  const routerTitle = getLabel(LangConstant.OBJ_ROUTER_TITLE, { returnObjects: true });
  const isLogin = AuthUtils.hasLogin();
  const isVerifiedSmartOTP = AuthUtils.hasVerifiedSmartOTP();
  const isFirstLogin = Boolean(StorageUtils.getStoreData(KeyConstant.KEY_FIRST_LOGIN));
  const isSelectBranch = Boolean(StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID));

  if (!isLogin && location.pathname !== PathConstant.LOGIN && location.pathname !== PathConstant.BRANCH_QR) {
    AuthUtils.handleLogout(false, false);
    window.location.replace(PathConstant.LOGIN);
    return;
  }

  if (isLogin && !isVerifiedSmartOTP && location.pathname !== PathConstant.VERIFY_SMART_OTP) {
    return <Redirect replace to={PathConstant.VERIFY_SMART_OTP} />;
  }

  if (isLogin && isVerifiedSmartOTP && isFirstLogin && location.pathname !== PathConstant.FIRST_LOGIN) {
    return <Redirect replace to={PathConstant.FIRST_LOGIN} />;
  }

  if (
    isLogin &&
    isVerifiedSmartOTP &&
    !isFirstLogin &&
    !isSelectBranch &&
    location.pathname !== PathConstant.BRANCH_MANAGEMENT
  ) {
    return <Redirect replace to={PathConstant.BRANCH_MANAGEMENT} />;
  }

  if (isLogin && isVerifiedSmartOTP && !isFirstLogin && location.pathname === PathConstant.FIRST_LOGIN) {
    return <Redirect replace to={PathConstant.ROOT} />;
  }

  if (isLogin && isVerifiedSmartOTP && isVerifiedSmartOTP && location.pathname === PathConstant.VERIFY_SMART_OTP) {
    return <Redirect replace to={PathConstant.ROOT} />;
  }

  if (isLogin && isVerifiedSmartOTP && location.pathname === PathConstant.LOGIN) {
    return <Redirect replace to={PathConstant.ROOT} />;
  }

  const withMainLayout = [
    PathConstant.COMPANY_MANAGEMENT,
    PathConstant.USER_MANAGEMENT,
    PathConstant.LICENSE,
    PathConstant.SYSTEM_TRACKING,
    PathConstant.SETTINGS,
    PathConstant.SERVER_MONITORING,
    PathConstant.POSITION_MANAGEMENT,
    PathConstant.MEDIA_MANAGEMENT,
    PathConstant.STATISTIC,
  ];

  return (
    <Fragment>
      <Helmet>
        <title>{getLabel(routerTitle[location.pathname])}</title>
      </Helmet>
      {withMainLayout.includes(location.pathname) ? (
        <MainLayout>
          <Switch>
            <Route component={CompanyManagement} exact path={PathConstant.COMPANY_MANAGEMENT} />
            <Route component={UserManagement} exact path={PathConstant.USER_MANAGEMENT} />
            <Route component={License} exact path={PathConstant.LICENSE} />
            <Route component={SystemTracking} exact path={PathConstant.SYSTEM_TRACKING} />
            <Route component={Settings} exact path={PathConstant.SETTINGS} />
            <Route component={Monitoring} exact path={PathConstant.SERVER_MONITORING} />
            <Route component={PositionManagement} exact path={PathConstant.POSITION_MANAGEMENT} />
            <Route component={MediaManagement} exact path={PathConstant.MEDIA_MANAGEMENT} />
            <Route component={Statistic} exact path={PathConstant.STATISTIC} />
          </Switch>
        </MainLayout>
      ) : (
        <Switch>
          <Redirect exact from={PathConstant.ROOT} to={PathConstant.BRANCH_MANAGEMENT} />
          <Route component={Qr} path={PathConstant.BRANCH_QR} />
          <Route component={Login} exact path={PathConstant.LOGIN} />
          <Route component={SmartOTP} exact path={PathConstant.VERIFY_SMART_OTP} />
          <Route component={FirstLogin} exact path={PathConstant.FIRST_LOGIN} />
          <Route component={BranchManagement} exact path={PathConstant.BRANCH_MANAGEMENT} />
          <Route component={NotFound} path="*" />
        </Switch>
      )}
    </Fragment>
  );
};
