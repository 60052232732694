import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import qs from "qs";

const useAccountGet = props => {
  const { t: getLabel } = useTranslation();
  const { accountId } = props;

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getAccount],

    queryFn: async () => {
      const apiPayload = {
        accountId: accountId,
      };
      let response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_USER,
        HumpUtils.decamelizeKeys(apiPayload),
      );

      return response.data;
    },

    onSuccess: response => {},

    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const { data: account } = data || {};

  return {
    account: account || [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useAccountGet;
