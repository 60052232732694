import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Dialog, Typography, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ConfirmDialog = ({ open, onClose, onSubmit, title, content, submit, exit, color }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <Typography sx={{ display: "flex", justifyContent: "center" }}>{content}</Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button variant="contained" color={color || "primary"} sx={{ width: "70%", marginBottom: "8px" }} onClick={onSubmit}>
          {submit}
        </Button>
        <Button sx={{ width: "70%", marginBottom: "8px", color: "#CBCACA" }} onClick={onClose}>
          {exit}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  status: {
    cursor: "pointer",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    fontWeight: "bold",
  },

  infoField: {
    margin: "10px 0px",
  },

  datePicker: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
