import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Dialog, Typography, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Clear } from "@mui/icons-material";

const MessageStatisticDialog = ({ open, onClose, message }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle className={classes.header}>
        <Typography className={classes.titleName}>Chỉ số thống kê</Typography>
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Box className={classes.popup}>
          <Typography>Tổng tin nhắn đã gửi</Typography>
          <Box className={classes.number}>
            {message.statistic_message?.success_send}/{message.statistic_message?.total_send}
          </Box>
          <Typography>Tỉ lệ gửi thành công</Typography>
          <Box className={classes.number}>
            {(message.statistic_message?.success_send / message.statistic_message?.total_send).toFixed(2) * 100 || 0}%
          </Box>
        </Box>
        <Box className={classes.popup}>
          <Typography>Tổng số người nhận tin nhắn</Typography>
          <Box className={classes.number}>
            {message.statistic_message?.total_receive}/{message.statistic_message?.success_send}
          </Box>
          <Typography>Tỉ lệ nhận tin nhắn thành công</Typography>
          <Box className={classes.number}>
            {(message.statistic_message?.total_receive / message.statistic_message?.success_send).toFixed(2) * 100 || 0}
            %
          </Box>
        </Box>
        <Box className={classes.popup}>
          <Typography>Tổng số người xem tin nhắn</Typography>
          <Box className={classes.number}>
            {message.statistic_message?.total_read}/{message.statistic_message?.success_send}
          </Box>
          <Typography>Tỉ lệ xem tin nhắn</Typography>
          <Box className={classes.number}>
            {(message.statistic_message?.total_read / message.statistic_message?.success_send).toFixed(2) * 100 || 0}%
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MessageStatisticDialog;

const useStyles = makeStyles(theme => ({
  popup: {
    width: 700,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "16px",
  },

  header: {
    backgroundColor: "#F2F6FC",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px",
  },

  titleName: {
    color: theme.palette.common.black,
    fontWeight: 700,
    textAlign: "center",
    fontSize: 20,
    width: "95%",
    paddingLeft: "16px",
  },

  content: {
    display: "flex",
    gap: "2px",
    backgroundColor: "#DFDFDF",
    padding: "0",
  },

  number: {
    fontSize: 24,
    color: "#008FE8",
    fontWeight: "bold",
  },
}));
