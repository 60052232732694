import React, { Fragment, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  TextField,
  TextareaAutosize,
  Button,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Clear, EditOutlined, FileUploadOutlined, Verified } from "@mui/icons-material";
import { useAccountGet, useUpdateUserAccount, useTusUpload, useTusGet } from "hooks";
import { StorageUtils, CommonUtils, SystemUtils } from "utils";
import { KeyConstant } from "const";
import localforage from "localforage";

const BotAccountDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const botAccountId = StorageUtils.getStoreData(KeyConstant.KEY_BOT_ACCOUNT_ID);

  const [botAccountName, setBotAccountName] = useState("");
  const [description, setDescription] = useState("");
  const [isDisableUpdate, setIsDisableUpdate] = useState(false);
  const [botAvatarId, setBotAvatarId] = useState("");

  const { account, refetch, isFetching } = useAccountGet({
    accountId: botAccountId,
  });
  const { updateUserAccountAsync } = useUpdateUserAccount();
  const { uploadAvatar } = useTusUpload();
  const { avatar } = useTusGet({ accountId: botAccountId, attachmentId: botAvatarId });

  const handleUpdateUser = () => {
    updateUserAccountAsync({
      accountId: botAccountId,
      name: botAccountName,
      description: description,
      avatar_id: botAvatarId,
      onClose: refetch,
    });
  };

  const onSuccessUpload = id => {
    setBotAvatarId(id);
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const id = CommonUtils.uuid();
      uploadAvatar(id, botAccountId, file, onSuccessUpload);
    }
  };

  useEffect(() => {
    setBotAccountName(account.name);
    setDescription(JSON.parse(account.options || "{}").description);
    setBotAvatarId(account.avatar_id);
  }, [account, open]);

  useEffect(() => {
    setIsDisableUpdate(
      !Boolean(botAccountName) ||
        (account.name === botAccountName &&
          JSON.parse(account.options || "{}").description === description &&
          account.avatar_id === botAvatarId) ||
        description?.length > 500,
    );
  }, [account, botAccountName, description, botAvatarId]);

  return (
    <Fragment>
      <Dialog
        open={open}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle className={classes.header}>
          <Typography className={classes.titleName}>Thông tin tài khoản</Typography>
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Box sx={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box className={classes.parentAvatar}>
              {Boolean(avatar) ? (
                <Box component="img" src={URL.createObjectURL(avatar)} className={classes.avatar} />
              ) : (
                <Box className={classes.avatar} />
              )}
              <Box className={classes.editAvatar}>
                <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                <Tooltip
                  title={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FileUploadOutlined />
                      Tải lên ảnh
                    </Box>
                  }
                  arrow
                >
                  <EditOutlined
                    sx={{ marginLeft: "35px" }}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  />
                </Tooltip>
              </Box>
              <Verified sx={{ bottom: 0, position: "absolute", marginLeft: "80px", color: "#008FE8" }} />
            </Box>
          </Box>
          <Box sx={{ paddingTop: "20px" }}>
            <TextField
              required
              fullWidth
              type="text"
              value={botAccountName}
              label="Tên tài khoản"
              onChange={e => {
                setBotAccountName(e.target.value);
              }}
              autoComplete="off"
              placeholder="Nhập tên tài khoản"
            />

            <Box sx={{ paddingTop: "8px" }}>
              <Typography sx={{ paddingLeft: "8px", fontWeight: "bold" }}>Thông tin mô tả</Typography>
              <TextareaAutosize
                className={description?.length > 500 ? classes.textAreaDanger : classes.textArea}
                minRows="6"
                maxRows="6"
                label="thông tin"
                placeholder="Nhập thông tin mô tả"
                onChange={e => {
                  setDescription(e.target.value);
                }}
                value={description}
                color="danger"
              />
              {description?.length > 500 ? (
                <Typography sx={{ paddingLeft: "8px", color: "red" }}>Nhập tối đa 500 ký tự</Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Button variant="contained" sx={{ marginTop: "28px" }} disabled={isDisableUpdate} onClick={handleUpdateUser}>
            Cập nhật
          </Button>
          <Button sx={{ marginTop: "8px", backgroundColor: "#F5F5F5" }} onClick={onClose}>
            Huỷ
          </Button>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default BotAccountDialog;

const useStyles = makeStyles(theme => ({
  popup: {
    width: 700,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "16px",
  },

  header: {
    backgroundColor: "#F2F6FC",
    height: "50px",
    display: "flex",
    alignItems: "center",
    padding: "8px 8px",
  },

  titleName: {
    color: theme.palette.common.black,
    fontWeight: 700,
    textAlign: "center",
    fontSize: 20,
    width: "95%",
    paddingLeft: "16px",
  },

  content: {
    display: "flex",
    gap: "2px",
    backgroundColor: "#FFFFFF",
    padding: "0",
    flexDirection: "column",
    padding: "18px",
  },

  number: {
    fontSize: 24,
    color: "#008FE8",
    fontWeight: "bold",
  },

  textArea: {
    width: "100%",
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
  },

  textAreaDanger: {
    width: "100%",
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid red",
    color: "red",
  },

  parentAvatar: {
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },

  avatar: {
    height: "120px",
    width: "120px",
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
  },

  editAvatar: {
    position: "absolute",
    bottom: 0,
    width: "95px",
    backgroundColor: "#D9D9D9",
    borderBottomLeftRadius: "28em 18em",
    borderBottomRightRadius: "28em 18em",
    opacity: "0.2",
    cursor: "pointer",
  },
}));
