import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { Clear } from "@mui/icons-material";
import { SystemConstant } from "const";
import { useBranchRequestUpdate, useDepartmentDetails, useUnitDetails, useUnitList } from "hooks";
import { format } from 'date-fns';

const FormUpdateBranchRequest = ({ user, isOpenFormAccept, onCloseForm, refetch, onOpenConfirm }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { updateBranchRequest } = useBranchRequestUpdate();

  const { totalUnitList } = useUnitList();
  const { positions: positionsByUnit, departments, handleGetUnitDetails } = useUnitDetails();
  const { positions: positionsByDepartment, handleGetDepartmentDetails } = useDepartmentDetails();
  const [positions, setPositions] = useState([]);

  const [updateBirthday, setUpdateBirthday] = useState(null);
  const [updateUnit, setUpdateUnit] = useState("");
  const [updateDepartment, setUpdateDepartment] = useState("");
  const [updatePosition, setUpdatePosition] = useState("");

  const handleChangeUnit = event => {
    setUpdateUnit(event.target.value);
    handleGetUnitDetails(event.target.value);
    setUpdateDepartment(null);
    setUpdatePosition(null);
  };

  const handleChangeDepartment = event => {
    setUpdateDepartment(event.target.value);
    handleGetDepartmentDetails(event.target.value);
    setUpdatePosition(null);
  };

  const handleChangePosition = event => {
    setUpdatePosition(event.target.value);
  };

  const handleAccept = () => {
    updateBranchRequest({
      branch_request_ids: [user.id],
      status: SystemConstant.BRANCH_REQUEST_STATUS.ACCEPTED.status,
      birthday: updateBirthday ? format(updateBirthday, "dd-MM-yyyy") : null,
      unit_id: updateUnit,
      department_id: updateDepartment,
      position_id: updatePosition,
      refetch: refetch,
      onClose: () => onCloseForm,
    });
  };

  useEffect(() => {
    if (positionsByUnit) setPositions(positionsByUnit);
  }, [positionsByUnit]);

  useEffect(() => {
    if (positionsByDepartment) setPositions(positionsByDepartment);
  }, [positionsByDepartment]);

  return (
    <Fragment>
      <Dialog open={isOpenFormAccept} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "8px 0" }}>
          <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>Thêm thành viên mới</Typography>
          <IconButton onClick={onCloseForm}>
            <Clear />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ height: "550px", overflowY: "scroll" }}>
          <Typography sx={{ fontWeight: "bold" }}>Ghi chú từ người yêu cầu:</Typography>
          <Typography sx={{ marginBottom: "8px" }}>{user.note}</Typography>

          <TextField
            label="Họ và tên (*)"
            fullWidth
            defaultValue={user?.name || ""}
            className={classes.infoField}
            disabled
            name="full-name"
          />
          <TextField
            label="Số điện thoại (*)"
            fullWidth
            defaultValue={user?.phone || ""}
            className={classes.infoField}
            disabled
            name="phone"
          />
          <TextField
            label="Email (*)"
            fullWidth
            type="email"
            defaultValue={user?.email || ""}
            className={classes.infoField}
            disabled
            name="email"
          />
          <Box className={classes.datePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ with: "100%" }}>
              <DatePicker
                label="Ngày sinh"
                value={updateBirthday}
                format="dd/MM/yyyy"
                className={classes.infoField}
                onChange={e => {
                  setUpdateBirthday(e);
                }}
              />
            </LocalizationProvider>
          </Box>
          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="unit-select-label">{getLabel("TXT_UNIT")}</InputLabel>
            <Select
              labelId="unit-select-label"
              id="unit-select"
              label={getLabel("TXT_UNIT")}
              value={updateUnit}
              onChange={handleChangeUnit}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_UNIT")}</MenuItem>
              {totalUnitList.map(unit => (
                <MenuItem value={unit.unitId} key={unit.unitId}>
                  {unit.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="department-select-label">{getLabel("TXT_DEPARTMENT")}</InputLabel>
            <Select
              labelId="department-select-label"
              id="department-select"
              label={getLabel("TXT_DEPARTMENT")}
              value={updateDepartment}
              onChange={handleChangeDepartment}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_DEPARTMENT")}</MenuItem>
              {departments.map(department => (
                <MenuItem value={department.departmentId} key={department.departmentId}>
                  {department.departmentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="position-select-label">{getLabel("TXT_POSITION")}</InputLabel>
            <Select
              labelId="position-select-label"
              id="position-select"
              label={getLabel("TXT_POSITION")}
              value={updatePosition}
              onChange={handleChangePosition}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_POSITION")}</MenuItem>
              {positions.map(position => (
                <MenuItem value={position.positionId} key={position.positionId}>
                  {position.positionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <Box
          sx={{ width: "100%", marginBottom: "8px", display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Button variant="contained" color="primary" sx={{ width: "90%", margin: "8px" }} onClick={handleAccept}>
            Thêm
          </Button>
          <Button variant="contained" color="error" sx={{ width: "90%", margin: "8px" }} onClick={onOpenConfirm}>
            Từ chối
          </Button>
        </Box>
      </Dialog>
    </Fragment>
  );
};

export default FormUpdateBranchRequest;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  status: {
    cursor: "pointer",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    fontWeight: "bold",
  },

  infoField: {
    margin: "10px 0px",
  },

  datePicker: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
