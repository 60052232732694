import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, LangConstant, KeyConstant } from "const";
import { ApiUtils, StorageUtils, SystemUtils } from "utils";
import apisauce from "apisauce";
import QueryString from "qs";

const useTusGet = props => {
  const { t: getLabel } = useTranslation();
  const accessToken = StorageUtils.getStoreData(KeyConstant.KEY_ACCESS_TOKEN);
  const tusDomain = StorageUtils.getStoreData(KeyConstant.KEY_TUS_DOMAIN);

  const { attachmentId, accountId } = props;
  const baseTusURL = "https://" + tusDomain + "/files/accounts/" + accountId + "/" + attachmentId;

  const createAPI = () => {
    try {
      const initConfig = {
        baseURL: baseTusURL,
        timeout: ApiConstant.TIMEOUT,
        responseType: "blob",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        paramsSerializer: params => {
          return QueryString.stringify(params, { arrayFormat: "repeat" });
        },
      };

      const api = apisauce.create(initConfig);

      return api;
    } catch (error) {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    }
  };

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getTusAvatar, attachmentId],
    enabled: Boolean(attachmentId),

    queryFn: async () => {
      let response = await createAPI().get(baseTusURL);
      return response.data;
    },

    onSuccess: response => {},

    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  return {
    avatar: data || "",
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useTusGet;
