import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useStatisticMessageGet() {
  const { t: getLabel } = useTranslation();

  const getStatisticMessageMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_STATISTIC_MESSAGE,
        HumpUtils.decamelizeKeys(payload),
      );
      return response.data;
    },
    onMutate: () => {
    },
    onSettled: () => {
    },
    onSuccess: async response => {},
    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const getStatisticMessage = async payload => {
    return getStatisticMessageMutation.mutateAsync(payload);
  };

  return { getStatisticMessage };
}
