import React, { useState } from "react";
import { SystemConstant } from "const";
import { makeStyles } from "@mui/styles";
import { Heading, BranchRequestListTable } from "./components";
import { useBranchRequestList } from "hooks";
import { debounce } from "lodash";

const UserRequestManagement = () => {
  const classes = useStyles();

  const [checkedUsers, setCheckedUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [choosedStatus, setChoosedStatus] = useState(SystemConstant.BRANCH_REQUEST_STATUS.ALL.status);
  const [page, setPage] = useState(1);

  const { branchRequestList, pagination, refetch, isFetching } = useBranchRequestList({
    page: page,
    filter: searchInput,
    status: choosedStatus,
  });

  const handleChangeStatus = event => {
    setChoosedStatus(event.target.value);
  };

  const handleChangeSearchInput = event => {
    setSearchInput(event.target.value);
    setPage(1);
  };

  const handleCheckboxChange = (isCheckAll, isChecked, id) => {
    if (isCheckAll) {
      if (isChecked) {
        setCheckedUsers(
          branchRequestList
            .filter(data => data.status === SystemConstant.BRANCH_REQUEST_STATUS.REJECTED.status)
            .map(data => data.id),
        );
      } else {
        setCheckedUsers([]);
      }
    } else {
      if (isChecked) {
        setCheckedUsers(state => [...state, id]);
      } else {
        setCheckedUsers(state => state.filter(index => index !== id));
      }
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  return (
    <>
      <Heading
        searchInput={searchInput}
        choosedStatus={choosedStatus}
        onChangeSearchInput={debounce(handleChangeSearchInput, 500)}
        onChangeStatusDropdown={handleChangeStatus}
        checkedUsers={checkedUsers}
        setCheckedUsers={setCheckedUsers}
        refetch={refetch}
      />

      <BranchRequestListTable
        isLoading={isFetching}
        branchRequestList={branchRequestList}
        pagination={pagination}
        onCheckBoxChange={handleCheckboxChange}
        checkedUsers={checkedUsers}
        onChangePage={handleChangePage}
        refetch={refetch}
      />
    </>
  );
};

export default UserRequestManagement;

const useStyles = makeStyles(theme => ({}));
