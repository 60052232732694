import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, LangConstant, } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import qs from "qs";

const useBotMessageList = props => {
  const { t: getLabel } = useTranslation();

  const maxRowPerPage = 10;
  const { page } = props;

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getBotMessageList, page],

    queryFn: async () => {
      const apiPayload = {
        paging: 1,
        limit: maxRowPerPage,
        asc: 0,
        offset: (page - 1) * maxRowPerPage,
        orderBy: "created",
      };
      let response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.GET_BOT_MESSAGE_LIST,
        qs.stringify(HumpUtils.decamelizeKeys(apiPayload)),
      );

      return response.data.data;
    },

    onSuccess: response => {},

    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const { data: botMessageList, ...pagination } = data || {};

  return {
    botMessageList: botMessageList || [],
    pagination: pagination || {},
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useBotMessageList;
