import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useBotMessageUpdate() {
  const { t: getLabel } = useTranslation();

  const { invalidateQueries } = useInvalidateQueries();

  const updateBotMessageMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_BOT_MESSAGE,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose, messageSucess }) => {
      if (response.status === ApiConstant.STT_OK) {
        if (!messageSucess) {
          SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
        } else {
          SystemUtils.appendNotification(messageSucess, "success");
        }
        onClose();
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updateBotMessage = payload => {
    updateBotMessageMutation.mutate(payload);
  };

  return { updateBotMessage };
}
