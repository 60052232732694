import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  SpeedDialIcon,
  Dialog,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { useBranchRequestUpdate } from "hooks";

const Heading = ({ searchInput, choosedStatus, onChangeSearchInput, onChangeStatusDropdown, checkedUsers, setCheckedUsers, refetch }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const { updateBranchRequest } = useBranchRequestUpdate();

  const [isOpenConfirmCancleReject, setIsOpenConfirmCancleReject] = useState(false);

  const handleCancleReject = () => {
    updateBranchRequest({
      branch_request_ids: checkedUsers,
      status: SystemConstant.BRANCH_REQUEST_STATUS.PENDING.status,
      refetch: refetch,
      onClose: () => {
        setIsOpenConfirmCancleReject(false);
        setCheckedUsers([])
      },
    });
  };

  const DialogConfirmCancleReject = () => {
    return (
      <Dialog open={isOpenConfirmCancleReject} maxWidth="sm" fullWidth>
        <DialogTitle className={classes.dialogTitle}>Xác nhận gỡ trạng thái từ chối</DialogTitle>
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          Bạn có muốn gỡ trạng thái từ chối không?
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "70%", marginBottom: "8px" }}
            onClick={handleCancleReject}
          >
            Xác nhận
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ width: "70%", marginBottom: "8px" }}
            onClick={() => {
              setIsOpenConfirmCancleReject(false);
            }}
          >
            Huỷ
          </Button>
        </Box>
      </Dialog>
    );
  };

  return (
    <Box className={classes.headerContainer}>
      <DialogConfirmCancleReject />

      <Box className={classes.searchBox}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          defaultValue={searchInput}
          onChange={onChangeSearchInput}
          label={getLabel(LangConstant.P_ADD_NEW_USER)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ ml: 1, width: 320 }}>
          <InputLabel>Trạng thái</InputLabel>
          <Select size="small" onChange={onChangeStatusDropdown} value={choosedStatus} label="Trạng thái">
            {Object.values(SystemConstant.BRANCH_REQUEST_STATUS)
              .filter(f => f != SystemConstant.BRANCH_REQUEST_STATUS.ACCEPTED)
              .map(userStatus => (
                <MenuItem key={userStatus.status} value={userStatus.status}>
                  {userStatus.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.topButtonContainer}>
        <Button
          className={classes.deleteButton}
          variant="contained"
          disabled={checkedUsers.length <= 0}
          onClick={() => {
            setIsOpenConfirmCancleReject(true);
          }}
        >
          Gỡ từ chối {checkedUsers.length > 0 ? "(" + checkedUsers.length + ")" : ""}
        </Button>
      </Box>
    </Box>
  );
};

export default Heading;

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    flexWrap: "wrap",
    gap: "16px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  topButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "8px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },

  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "680px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },

  mainContainer: {
    marginBottom: 16,
  },

  speedDial: {
    position: "absolute",
    top: "-18px",
    right: 0,
    fontSize: "12px",

    "& > button": {
      width: "120px",
      height: "36px",
      borderRadius: "4px",
    },

    "& .MuiSpeedDialAction-staticTooltipLabel": {
      width: "200px !important",
    },
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    fontWeight: "bold",
  },
}));
