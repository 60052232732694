import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StaticDatePicker, LocalizationProvider, YearCalendar } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CommonUtils } from "utils";
import { CloseOutlined } from "@mui/icons-material";
import { vi } from "date-fns/locale";
import dayjs from "dayjs";

const SelectDateTime = ({ open, setSinceTime, setLastTime, setLabel, onClose }) => {
  const classes = useStyles();

  const [isOpenYearPicker, setIsOpenYearPicker] = useState(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [tmpSinceTime, setTmpSinceTime] = useState("");
  const [tmpLastTime, setTmpLastTime] = useState("");

  const options = [
    {
      id: 0,
      label: "Hôm nay",
    },
    {
      id: 1,
      label: "7 ngày gần nhất",
    },
    {
      id: 2,
      label: "1 tháng gần nhất",
    },
    {
      id: 3,
      label: "3 tháng gần nhất",
    },
    {
      id: 4,
      label: "Theo năm",
    },
    {
      id: 5,
      label: "Tuỳ chỉnh",
    },
  ];

  const onChangeOptions = item => {
    switch (item.id) {
      case 0: {
        setIsOpenYearPicker(false);
        setIsOpenDatePicker(false);
        setSinceTime(CommonUtils.getStartToday());
        setLastTime(null);
        setLabelSelectDateTime(item.label);
        break;
      }
      case 1: {
        setIsOpenYearPicker(false);
        setIsOpenDatePicker(false);
        setSinceTime(CommonUtils.getStartToday() - 7 * 86400000);
        setLastTime(null);
        setLabelSelectDateTime(item.label);
        break;
      }
      case 2: {
        setIsOpenYearPicker(false);
        setIsOpenDatePicker(false);
        setSinceTime(CommonUtils.getStartToday() - 30 * 86400000);
        setLastTime(null);
        setLabelSelectDateTime(item.label);
        break;
      }
      case 3: {
        setIsOpenYearPicker(false);
        setIsOpenDatePicker(false);
        setSinceTime(CommonUtils.getStartToday() - 3 * 30 * 86400000);
        setLastTime(null);
        setLabelSelectDateTime(item.label);
        break;
      }
      case 4: {
        setIsOpenYearPicker(true);
        setIsOpenDatePicker(false);
        break;
      }
      case 5: {
        setIsOpenYearPicker(false);
        setIsOpenDatePicker(true);
        break;
      }
      default: {
        break;
      }
    }
  };

  const setLabelSelectDateTime = label => {
    setLabel(label);
    if (label !== "Tuỳ chỉnh") {
      setTmpSinceTime("");
      setTmpLastTime("");
    }
    onClose();
  };

  const onChangeYear = e => {
    setSinceTime(e.getTime());
    setLastTime(e.getTime() + 365 * 86400000);
    setLabelSelectDateTime(e.getFullYear());
  };

  const onConfirmDateTime = () => {
    setSinceTime(tmpSinceTime.getTime());
    setLastTime(tmpLastTime.getTime());
    setLabelSelectDateTime(
      dayjs(tmpSinceTime.getTime()).format("DD/MM/YYYY") + " - " + dayjs(tmpLastTime.getTime()).format("DD/MM/YYYY"),
    );
  };

  useEffect(() => {
    setIsOpenYearPicker(false);
    setIsOpenDatePicker(false);
  }, [open]);

  const YearComponent = () => {
    return (
      <Box className={classes.yearPicker}>
        <Box
          sx={{
            height: "30px",
            display: "flex",
            borderBottom: "1px solid #DFDFDF",
            justifyContent: "flex-end",
            paddingRight: "12px",
            alignItems: "center",
          }}
        >
          <CloseOutlined
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpenYearPicker(false);
            }}
          />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <YearCalendar orientation="landscape" disableFuture onChange={onChangeYear} />
        </LocalizationProvider>
      </Box>
    );
  };

  const DateComponent = () => {
    return (
      <Box className={classes.datePicker}>
        <Box sx={{ display: "flex" }}>
          <LocalizationProvider adapterLocale={vi} dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              disableFuture
              slotProps={{ actionBar: { actions: [] } }}
              value={tmpSinceTime}
              onChange={e => setTmpSinceTime(e)}
            />
            <StaticDatePicker
              disableFuture
              slotProps={{ actionBar: { actions: [] } }}
              value={tmpLastTime}
              onChange={e => setTmpLastTime(e)}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "12px", gap: "12px" }}>
          <Button variant="contained" color="error" onClick={() => setIsOpenDatePicker(false)}>
            Huỷ
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirmDateTime}
            disabled={!Boolean(tmpSinceTime) || !Boolean(tmpLastTime)}
          >
            Chọn
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {open ? (
        <Box className={classes.container}>
          <Box className={classes.menuOptions}>
            {options.map(item => (
              <Typography className={classes.options} onClick={() => onChangeOptions(item)}>
                {item.label}
              </Typography>
            ))}
          </Box>

          {isOpenYearPicker && <YearComponent />}
          {isOpenDatePicker && <DateComponent />}
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectDateTime;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row-reverse",
    gap: "12px",
  },

  menuOptions: {
    width: "200px",
    height: "160px",
    border: "2px solid #DFDFDF",
    borderRadius: "4px",
    padding: "10px",
    zIndex: 4,
    backgroundColor: "white",
  },

  options: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "cornflowerblue",
      color: "white",
    },
  },

  yearPicker: {
    border: "2px solid #DFDFDF",
    borderRadius: "4px",
    zIndex: 4,
    backgroundColor: "white",
  },

  datePicker: {
    display: "flex",
    border: "2px solid #DFDFDF",
    borderRadius: "4px",
    zIndex: 4,
    backgroundColor: "white",
    flexDirection: "column",
  },
}));
