import React, { Fragment, useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, Typography, MenuItem } from "@mui/material";
import { CalendarMonthOutlined, ArrowDropDownOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonUtils, StorageUtils, DatetimeUtils } from "utils";
import "chart.js/auto";
import { Doughnut, Bar } from "react-chartjs-2";
import { useStatisticMessageGet } from "hooks";
import { KeyConstant } from "const";
import SelectDateTime from "./SelectDateTime";

const CallStatistic = ({ unitList }) => {
  const classes = useStyles();
  const { getStatisticMessage } = useStatisticMessageGet();
  const branchName = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_NAME);

  const [unitId, setUnitId] = useState(0);
  const [labelCalendar, setLabelCalendar] = useState("Hôm nay");
  const [totalCall, setTotalCall] = useState(0);
  const [dataBarCall, setDataBarCall] = useState("");
  const [dataDoughnutCall, setDataDoughnutCall] = useState("");
  const [optionsDoughtnutCall, setOptionsDoughtnutCall] = useState("");
  const [sinceTime, setSinceTime] = useState(CommonUtils.getStartToday());
  const [lastTime, setLastTime] = useState(null);

  const [openDateTime, setOpenDateTime] = useState(false);

  const onChangeUnit = e => {
    setUnitId(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {};
      if (unitId === 0) {
        payload.include_unit_id = 0;
      } else {
        payload.include_unit_id = 1;
      }
      if (unitId !== 0 && unitId !== -1) {
        payload.unit_id = unitId;
      }
      if (sinceTime) {
        payload.since_time = sinceTime;
      }
      if (lastTime) {
        payload.last_time = lastTime;
      }

      const response = await getStatisticMessage(payload);
      if (response) {
        const labelBar = response.data.map(item => item.date);
        const dataBar = response.data.map(item => item.call_accept + item.call_missed + item.call_reject);
        const dataDoughnut = response.data.reduce(
          (acc, item) => {
            acc[0] += item.call_accept;
            acc[1] += item.call_missed;
            acc[2] += item.call_reject;
            return acc;
          },
          [0, 0, 0],
        );

        setTotalCall(dataBar.reduce((sum, element) => sum + element, 0));

        setDataBarCall({
          labels: labelBar,
          datasets: [
            {
              label: "Số cuộc gọi",
              data: dataBar,
              backgroundColor: ["#357AF6"],
            },
          ],
        });

        setDataDoughnutCall({
          labels: ["Cuộc gọi thành công", "Cuộc gọi nhỡ", "Cuộc gọi bị huỷ"],
          datasets: [
            {
              label: "Số cuộc gọi",
              data: dataDoughnut,
              backgroundColor: dataDoughnut.map(() => CommonUtils.getRandomColor()),
              hoverOffset: 4,
            },
          ],
        });

        setOptionsDoughtnutCall({
          cutout: "70%",
          radius: "80%",
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              maxWidth: 220,
              labels: {
                usePointStyle: false,
                boxWidth: 15,
                boxHeight: 15,
              },
            },
          },
        });
      }
    };

    fetchData();
  }, [unitId, sinceTime, lastTime]);

  return (
    <Fragment>
      <Box className={classes.heading}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}> Tổng quan cuộc gọi </Typography>
        <Box sx={{ display: "flex", gap: "12px", position: "relative" }}>
          <FormControl sx={{ ml: 1, width: 200 }}>
            <InputLabel>Phân loại</InputLabel>
            <Select size="small" onChange={onChangeUnit} value={unitId} label="Phân loại">
              {unitList.map(data => (
                <MenuItem key={data.unitId} value={data.unitId}>
                  {data.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            className={classes.calendar}
            onClick={() => {
              setOpenDateTime(!openDateTime);
            }}
          >
            <CalendarMonthOutlined />
            <Typography> {labelCalendar} </Typography>
            <ArrowDropDownOutlined />
          </Box>
          <Box sx={{ position: "absolute", zIndex: 4, top: "42px", right: "0px" }}>
            <SelectDateTime
              open={openDateTime}
              setLabel={setLabelCalendar}
              onClose={() => setOpenDateTime(false)}
              setSinceTime={setSinceTime}
              setLastTime={setLastTime}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.chartParent}>
        <Box className={classes.chartChild1}>
          <Typography className={classes.chartLabel}>
            Tổng số lượng cuộc gọi đã thực hiện:
            <Typography sx={{ marginLeft: "8px", fontSize: "20px", fontWeight: "bold", color: "cornflowerblue" }}>
              {totalCall} cuộc gọi
            </Typography>
          </Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "90%" }}>{Boolean(dataBarCall) && <Bar data={dataBarCall} />}</Box>
          </Box>
        </Box>

        <Box className={classes.chartChild2}>
          <Typography className={classes.chartLabel}>Tỉ lệ trạng thái cuộc gọi</Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "450px" }}>
              {Boolean(dataDoughnutCall) && <Doughnut data={dataDoughnutCall} options={optionsDoughtnutCall} />}
            </Box>
            <Box className={classes.textInnerChart}>
              <Typography>Tổng số cuộc gọi</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{totalCall}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ color: "#A8A8A8" }}>
        *Dữ liệu cập nhật mới nhất vào 00:01 mỗi ngày
      </Typography>
    </Fragment>
  );
};

export default CallStatistic;

const useStyles = makeStyles(() => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    borderBottom: "1px solid #DFDFDF",
  },

  calendar: {
    minWidth: "200px",
    height: "40px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    justifyContent: "space-between",
    maxWidth: "250px",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #000000",
    },
  },

  chartParent: {
    height: 550,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  chartChild1: {
    width: "60%",
    height: "90%",
    border: "1px solid #DFDFDF",
    borderRadius: "12px",
    padding: "16px",
    position: "relative",
  },

  chartChild2: {
    width: "35%",
    height: "90%",
    border: "1px solid #DFDFDF",
    borderRadius: "12px",
    padding: "16px",
    position: "relative",
  },

  chartLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },

  textInnerChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "45%",
  },
}));
