import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useUpdateUserAccount() {
  const { t: getLabel } = useTranslation();
  const { invalidateUserQueries } = useInvalidateQueries();

  const updateUserAccountMutation = useMutation({
    mutationFn: async payload => {
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_USER_ACCOUNT,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        onClose();
        await invalidateUserQueries();
        await ApiUtils.apiCreateUserActivitiesLog(
          SystemConstant.LOGGER_ACTION_TYPES.UPDATE_USER_INFORMATION,
          StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID),
        );
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_SUCCESS), "success");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updateUserAccount = payload => {
    updateUserAccountMutation.mutate(payload);
  };

  const updateUserAccountAsync = async payload => {
    return updateUserAccountMutation.mutateAsync(payload);
  };

  return { updateUserAccount, updateUserAccountAsync };
}
