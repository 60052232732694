import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useStatisticUserGet() {
  const { t: getLabel } = useTranslation();

  const getStatisticUserMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_STATISTIC_USER,
        HumpUtils.decamelizeKeys(payload),
      );
      return response.data;
    },
    onMutate: () => {
    },
    onSettled: () => {
    },
    onSuccess: async response => {},
    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const getStatisticUser = async payload => {
    return getStatisticUserMutation.mutateAsync(payload);
  };

  return { getStatisticUser };
}
