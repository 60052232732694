import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined, ComputerOutlined, PhoneAndroidOutlined, ArrowBackOutlined } from "@mui/icons-material";
import { KeyConstant, ImageConstant } from "const";
import { BranchUtils, StorageUtils } from "utils";
import localforage from "localforage";
import clsx from "clsx";

const PreviewInformationDialog = ({ open, onClose, contentInformation }) => {
  const classes = useStyles();

  const currentBranch = BranchUtils.getCurrentBranch();

  const [isScreenDesktop, setIsScreenDesktop] = useState(true);
  const [isScreenMobile, setIsScreenMobile] = useState(false);
  const [branchAvatar, setBranchAvatar] = useState("");

  useEffect(() => {
    const branchAttachmentId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ATTACHMENT);
    localforage.getItem(KeyConstant.KEY_CACHED_BRANCH_AVATAR_BLOB + branchAttachmentId).then(avatar => {
      setBranchAvatar(URL.createObjectURL(avatar));
    });
  }, []);

  const switchActiveScreen = e => {
    if (e === 0) {
      setIsScreenDesktop(true);
      setIsScreenMobile(false);
    } else {
      setIsScreenDesktop(false);
      setIsScreenMobile(true);
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.component }} fullWidth>
      <Box className={classes.heading}>
        <Box className={classes.boxSwitchScreen}>
          <Box
            className={isScreenDesktop ? classes.btnActiveScreen : classes.btnInactiveScreen}
            onClick={e => switchActiveScreen(0)}
          >
            <ComputerOutlined /> Máy tính
          </Box>
          <Box
            className={isScreenMobile ? classes.btnActiveScreen : classes.btnInactiveScreen}
            onClick={e => switchActiveScreen(1)}
          >
            <PhoneAndroidOutlined />
            Di động
          </Box>
        </Box>
        <Button onClick={onClose}>
          <CloseOutlined fill="black" />
        </Button>
      </Box>

      <DialogContent className={classes.dialogContent}>
        <Box className={isScreenDesktop ? classes.screenDesktop : classes.screenMobile}>
          {isScreenMobile && (
            <Box sx={{ padding: "12px", display: "flex", justifyContent: "space-between" }}>
              <ArrowBackOutlined />
              <Typography sx={{ fontWeight: "bold", fontSize: "15px" }}>Thông tin sản phẩm</Typography>
              <Box sx={{ width: "25px" }}></Box>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
            <Box
              component="img"
              className={isScreenDesktop ? classes.branchLogoDesktop : classes.branchLogoMobile}
              src={branchAvatar || ImageConstant.TriosLogo}
            />
            <Typography sx={{ fontWeight: "bold", fontSize: isScreenDesktop ? "24px" : "16px" }}>
              {" "}
              {currentBranch?.name}{" "}
            </Typography>
          </Box>
          <Box
            className={clsx("ql-editor", classes.infoDetailBox)}
            dangerouslySetInnerHTML={{
              __html: contentInformation,
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewInformationDialog;

const useStyles = makeStyles(() => ({
  btnInactiveScreen: {
    width: "20%",
    fontSize: "larger",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    cursor: "pointer",
  },

  btnActiveScreen: {
    width: "20%",
    fontSize: "larger",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    cursor: "pointer",
    color: "cornflowerblue",
    borderBottom: "3px solid ",
  },

  heading: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    backgroundColor: "#F2F6FC",
  },

  boxSwitchScreen: {
    width: "95%",
    height: "98%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "10%",
    gap: "8px",
  },

  textArea: {
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
    boxShadow: "0 0 5px 2px",

    "&:hover": {
      boxShadow: "0 0 5px 3px cornflowerblue",
    },
  },

  component: {
    minWidth: "1208px",
    minHeight: "738px",
  },

  screenDesktop: {
    display: "flex",
    width: "1200px",
    height: "730px",
    backgroundColor: "white",
    flexDirection: "column",
    paddingTop: "8%",
    gap: "20px",
    overflowY: "auto",
  },

  screenMobile: {
    display: "flex",
    width: "360px",
    height: "700px",
    backgroundColor: "white",
    flexDirection: "column",
    gap: "20px",
    overflowY: "auto",
  },

  dialogContent: {
    padding: "4px",
    backgroundColor: "#343D3F",
    display: "flex",
    justifyContent: "center",
  },

  infoDetailBox: {
    height: "fit-content",
    overflowY: "unset",
    "& img": {
      maxWidth: "100%",
    },
    flexGrow: 1,
  },

  branchLogoDesktop: {
    backgroundSize: "contain",
    width: "100px",
    height: "100px",
  },

  branchLogoMobile: {
    backgroundSize: "contain",
    width: "40px",
    height: "40px",
  },

  serverName: {},
}));
