import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useGetBranchQR() {
  const { t: getLabel } = useTranslation();
  const messageError = getLabel(LangConstant.OBJ_ERROR_MSG, { returnObjects: true });

  const { invalidateQueries } = useInvalidateQueries();

  const getBranchQRMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      const response = await ApiUtils.createApiWithToken(false, {
        ...ApiUtils.API_CONFIG,
        responseType: "blob",
      }).post(ApiConstant.CREATE_BRANCH_QR, qs.stringify(HumpUtils.decamelizeKeys(payload)));
      return URL.createObjectURL(response.data);
    },
    onMutate: () => {
    },
    onSettled: () => {
    },
    onSuccess: async response => {
    },
    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const getBranchQR = async payload => {
    return getBranchQRMutation.mutateAsync(payload);
  };

  return { getBranchQR };
}
