import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useStatisticGroupGet() {
  const { t: getLabel } = useTranslation();

  const getStatisticGroupMutation = useMutation({
    mutationFn: async data => {
      const payload = data;
      if (payload.export_f === 1) {
        const response = await ApiUtils.createApiWithToken(false, { ...ApiUtils.API_CONFIG, responseType: "blob" }).get(
          ApiConstant.GET_STATISTIC_GROUP,
          HumpUtils.decamelizeKeys(payload),
        );
        return URL.createObjectURL(response.data);
      } else {
        const response = await ApiUtils.createApiWithToken(false).get(
          ApiConstant.GET_STATISTIC_GROUP,
          HumpUtils.decamelizeKeys(payload),
        );
        return response.data;
      }
    },
    onMutate: () => {
    },
    onSettled: () => {
    },
    onSuccess: async response => {},
    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const getStatisticGroup = async payload => {
    return getStatisticGroupMutation.mutateAsync(payload);
  };

  return { getStatisticGroup };
}
