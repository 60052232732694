import { ApiConstant, PathConstant, SystemConstant } from "const";

const en = {
  login: {
    // TEXTS
    TXT_LOGIN: "Login as Administrator",
    TXT_CHANGE_INFO: "Change your information",
    TXT_ADD_AVATAR: "Add your company photo",

    // LABELS
    L_USERNAME: "Username",
    L_PASSWORD: "Password",
    L_REMEMBER_ME: "Remember me",
    L_LOGIN_BUTTON: "Sign in",
    L_NEW_PASSWORD: "Enter your new password",
    L_NEW_PASSWORD_CONFIRMATION: "Confirm your new password",
    L_CONTINUE_BUTTON: "Continue",

    // PLACEHOLDERS
    P_USERNAME: "Enter your username",
    P_PASSWORD: "Enter your password",
    P_NEW_PASSWORD: "Enter your new password",

    // ERROR OBJECT
    ERR_OBJECT: {
      [ApiConstant.STT_UNAUTHORIZED]: "Wrong username or password. Please try again!",
      [ApiConstant.STT_NOT_FOUND]: "Wrong username or password. Please try again!",
      passwordConfirmationNotMatch: "Password and confirm password does not match",
      imageNotValid: "Please choose PNG or JPG",
      somethingWrong: "Something wrong, please try again later",
    },
  },

  common: {
    // OBJECTS
    OBJ_ROUTER_TITLE: {
      [PathConstant.LOGIN]: "Login as Administrator",
      [PathConstant.FIRST_LOGIN]: "Change your company information",
      [PathConstant.COMPANY_MANAGEMENT]: "Company Management",
      [PathConstant.USER_MANAGEMENT]: "User Management",
      [PathConstant.LICENSE]: "License",
      [PathConstant.SETTINGS]: "Settings",
      [PathConstant.SYSTEM_TRACKING]: "System Tracking",
      [PathConstant.BRANCH_MANAGEMENT]: "Branch Management",
      [PathConstant.VERIFY_SMART_OTP]: "SMART OTP Verification",
      [PathConstant.POSITION_MANAGEMENT]: "Position Management",
      [PathConstant.MEDIA_MANAGEMENT]: "Media Management",
    },

    OBJ_SIDEBAR_TITLE: {
      companyManagement: "Company Management",
      userManagement: "User Management",
      license: "License",
      settings: "Settings",
      logout: "Logout",
      systemTracking: "System Tracking",
      branchManagement: "Branch Server Management",
      serverMonitoring: "Server Monitoring",
      positionManagement: "Position Management",
      mediaManagement: "Media Management",
      statistic: "Statistic",
    },

    OBJ_ERROR_MSG: {
      wrongPassword: "Wrong password! Please try again",
      passwordConfirmationNotMatch: "Password does not match",
    },

    OBJ_USER_DETAIL: {
      userName: "Name",
      fullName: "Full Name",
      phone: "Phone",
      email: "Email",
      birthday: "Birthday",
      activeStatus: "Active Status",
      joinStatus: "Join Status",
      unit: "Unit",
      department: "Department",
    },

    OBJ_BRANCH_ERROR: {
      updateTime: "Update time",
      fromDate: "From Date",
      toDate: "To Date",
      search: "Search",
      branchServer: "Branch Server",
    },

    OBJ_TABLE_ERROR: {
      number: "No.",
      errorFeature: "Error Name",
      errorComponent: "Error Component",
      time: "Created",
      description: "Description",
      rowsPerPage: "Rows per page",
    },

    OBJ_TABLE_USER_ACTIVITIES: {
      number: "No.",
      user: "User ID",
      action: "Action",
      time: "Time",
      ipAddress: "IP Address",
      rowsPerPage: "Rows per page",
    },

    OBJ_TABLE_BRANCH: {
      number: "No",
      avatar: "Avatar",
      branchName: "Branch Server Name",
      package: "Package",
      packageId: "Package ID",
      license: "License Number",
      existAccount: "Registered Account",
      status: "Status",
      action: "Action",
    },

    OBJ_CONFIRM_RESET: {
      title: "Confirm reset password",
      content: "Do you want to reset password for selected user ?",
      acceptButton: "Accept",
      cancelButton: "Cancel",
    },

    OBJ_BRANCH_ERROR_TYPE: {
      sendMessage: "Send Message",
      audioCall: "Audio Call",
      videoCall: "Video Call",
      audioGroupCall: "Audio Group Call",
      videoGroupCall: "Video Group Call",
      updateProfile: "Update Profile",
    },

    OBJ_RANDOM_ADMIN_PASSWORD: {
      title: "New password",
      content: "New generated administrator's password is: {{password}}",
      close: "Close",
      copy: "Copy password",
    },

    OBJ_USER_ACTIVITIES: {
      [SystemConstant.LOGGER_ACTION_TYPES.LOGIN]: "Login",
      [SystemConstant.LOGGER_ACTION_TYPES.LOGOUT]: "Logout",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_INFORMATION]: "Update branch information",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_NEW_USERS]: "Add user into branch",
      [SystemConstant.LOGGER_ACTION_TYPES.ACTIVE_USER]: "Active user",
      [SystemConstant.LOGGER_ACTION_TYPES.BLOCK_USER]: "Block user",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_USER_INFORMATION]: "Update user information",
      [SystemConstant.LOGGER_ACTION_TYPES.RESET_USER_PASSWORD]: "Reset user password",
      [SystemConstant.LOGGER_ACTION_TYPES.CHANGE_PASSWORD]: "Change password",
      [SystemConstant.LOGGER_ACTION_TYPES.UPDATE_BRANCH_CONFIGS]: "Update branch configs",
      [SystemConstant.LOGGER_ACTION_TYPES.IMPORT_NEW_USERS_FROM_EXCEL]: "Add new users using Excel file",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_BRANCH]: "Add branch server",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_BRANCH]: "Delete branch serve",
      [SystemConstant.LOGGER_ACTION_TYPES.ACTIVATE_BRANCH]: "Activate branch",
      [SystemConstant.LOGGER_ACTION_TYPES.DEACTIVATE_BRANCH]: "Deactivate branch",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT]: "Create unit",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_UNIT]: "Edit unit",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_UNIT]: "Delete unit",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_DEPARTMENT]: "Create department",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_DEPARTMENT]: "Edit department",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_DEPARTMENT]: "Delete department",
      [SystemConstant.LOGGER_ACTION_TYPES.ADD_DEPARTMENT_USERS]: "Add department users",
      [SystemConstant.LOGGER_ACTION_TYPES.REMOVE_DEPARTMENT_USERS]: "Remove department users",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_UNIT_CHANNEL]: "Create unit channel",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_DEPARTMENT_CHANNEL]: "Create department channel",
      [SystemConstant.LOGGER_ACTION_TYPES.CREATE_POSITION]: "Create position",
      [SystemConstant.LOGGER_ACTION_TYPES.EDIT_POSITION]: "Edit position",
      [SystemConstant.LOGGER_ACTION_TYPES.DELETE_POSITION]: "Delete position",
    },

    OBJ_USER_FILTER_STATUS: {
      inactive: "Inactive",
      active: "Joined",
      all: "All",
      pending: "Pending",
    },

    OBJ_UNIT_LIST_TABLE: {
      name: "Unit/department name",
      departmentNumber: "Number of departments",
      employeeNumber: "Number of employees",
    },

    OBJ_UNIT_DETAIL: {
      name: "Unit name",
      branch: "Affiliated with the branch",
    },

    OBJ_UNIT_ERROR: {
      [SystemConstant.UNIT_ERROR.MISSING_PARAMETERS]: "Mising parameters",
      [SystemConstant.UNIT_ERROR.IN_WRONG_FORMAT]: "Unit name is in wrong format",
      [SystemConstant.UNIT_ERROR.DUPLICATED]: "Unit exists",
      [SystemConstant.UNIT_ERROR.CONTAIN_DEPARTMENTS]: "This unit cannot be deleted, it has affiliated departments",
    },

    OBJ_DEPARTMENT_DETAIL: {
      name: "Department name",
      unit: "Affiliated with the unit",
    },

    OBJ_DEPARTMENT_ERROR: {
      [SystemConstant.DEPARTMENT_ERROR.MISSING_PARAMETERS]: "Mising parameters",
      [SystemConstant.DEPARTMENT_ERROR.IN_WRONG_FORMAT]: "Department name is in wrong format",
      [SystemConstant.DEPARTMENT_ERROR.DUPLICATED]: "Department exists",
      [SystemConstant.DEPARTMENT_ERROR.CHANNEL_DUPLICATED]: "Channel exists",
      [SystemConstant.DEPARTMENT_ERROR.CHANNEL_MISSING_PARAMETERS]: "Channel missing parameters",
    },

    OBJ_POSITION_ERROR: {
      [SystemConstant.DEPARTMENT_ERROR.MISSING_PARAMETERS]: "Mising parameters",
      [SystemConstant.DEPARTMENT_ERROR.IN_WRONG_FORMAT]: "Position name is in wrong format",
      [SystemConstant.DEPARTMENT_ERROR.DUPLICATED]: "Position name  is existed",
    },

    OBJ_CHANNEL_DIALOG: {
      unitTitle: "Create unit channel",
      departmentTitle: "Create department channel",
      channelName: "Channel name",
      channelMode: "Channel mode",
      channelManager: "Channel manager",
      unitConfirm: "Do you want to create a channel for this unit?",
      departmentConfirm: "Do you want to create a channel for this department?",
      noUser: "No user",
    },

    // LABELS
    L_NEED_HELP_BUTTON: "Need help?",
    L_COMPANY_NAME: "Company name",
    L_COMPANY_AVATAR: "Avatar",
    L_CHANGE_COMPANY_AVATAR_BUTTON: "Change Avatar",
    L_CONFIRM_BUTTON: "Confirm",
    L_DISCARD_BUTTON: "Discard",
    L_DELETE_USER_BUTTON: "Delete",
    L_ADD_NEW_USER_BUTTON: "Add New",
    L_JOINED_STATUS: "Joined",
    L_PENDING_STATUS: "Pending",
    L_REFUSED_STATUS: "Refused",
    L_REMOVE_BUTTON: "Disable",
    L_CANCEL_BUTTON: "Cancel",
    L_ADD_BUTTON: "Add",
    L_ACTIVE: "Active",
    L_ACTIVATION_STATE: "Activation state",
    L_EXPIRED: "Expired",
    L_UPGRADE_LICENSE: "Upgrade your license",
    L_PASSWORD_LAST_UPDATE: "Last update: {{time}}",
    L_OLD_PASSWORD: "Enter your old password",
    L_UPDATE_PASSWORD_BUTTON: "Update password",
    L_LANGUAGE: "Language",
    L_VIETNAMESE_LANGUAGE: "Vietnamese",
    L_ENGLISH_LANGUAGE: "English",
    L_SAVE_BUTTON: "Save change",
    L_PUBLIC_MODE: "Public",
    L_PRIVATE_MODE: "Private",
    L_PASSWORD_MODE: "Password",
    L_OTP_MODE: "OTP",
    L_ACTIVE_USER: "Active",
    L_INACTIVE_USER: "Inactive",
    L_BRANCH_LIST: "Branch List",
    L_OK: "OK",
    L_CREATE: "Create",
    L_SWITCH_BRANCH: "Switch branch",
    L_ACTIVE_ACTION: "Enable",
    L_INACTIVE_ACTION: "Disable",
    L_UPDATE: "Update",
    L_AGREE: "OK",
    L_SAVE_INFO: "Save Information",
    L_CANCEL_ADD_BRANCH: "Cancel",
    L_STATUS: "Status",
    L_ADD_UNIT_BUTTON: "Add unit",
    L_ACCEPT: "Accept",
    L_DOWNLOAD_FILE: "Download file",
    L_DELETE: "Delete",
    L_CANCEL: "Cancel",
    L_ALL: "All",
    L_CLOSE: "Close",
    L_SELECT_UNIT: "Select unit",

    // PLACEHOLDERS
    P_COMPANY_NAME: "Enter your company name",
    P_ADD_NEW_USER: "Search by user name or phone number",
    P_OLD_PASSWORD: "Enter your old password",
    P_SEARCH: "Search",
    P_SEARCH_UNIT: "Search by unit name",
    P_SEARCH_POSITION: "Search by position name",
    P_POSITION_NAME: "Enter position name",
    P_SEARCH_USER_BY_NAME_PHONE_EMAIL: "Search by user name, phone number or email",
    P_SEARCH_USER_BY_NAME_EMAIL: "Search by user name or email",

    // TEXTS
    TXT_APP_COPYRIGHT: "Copyright © bGlobal Jsc. All Rights Reserved",
    TXT_COMPANY_PROFILE: "Company profile",
    TXT_INVITE_AGAIN: "Invite again",
    TXT_INVITED: "Invited",
    TXT_CONFIRM_REMOVE_USER_HEADING: "Confirm disable",
    TXT_ADD_NEW_USER: "Add new user",
    TXT_SELECTED_USER: "Selected user",
    TXT_CONTACT_FOUND: "Contact found",
    TXT_NOT_FOUND: "Not found",
    TXT_LOADING: "Loading",
    TXT_SERVER_EXPIRED: "This server expired",
    TXT_SERVER_ACTIVE: "This server activated with a {{name}} license until",
    TXT_UPGRADE_LICENSE_PHONE_NUM: "Want to upgrade your license? Please call",
    TXT_UPDATE_PASSWORD_SUCCESS: "Update password successfully",
    TXT_INFORMATION: "User information",
    TXT_EDIT_INFO: "Edit user information",
    TXT_RESET_PASSWORD: "Reset password",
    TXT_DETAILS_INFO: "User information",
    TXT_MODE: "Mode",
    TXT_BRANCH_MODE: "Branch mode",
    TXT_LOGIN_MODE: "Login mode",
    TXT_ADD_BRANCH: "Add branch",
    TXT_CONFIRM_DELETE_BRANCH: "Do you want to delete this branch?",
    TXT_NOTIFICATION_ERROR_TITLE: "Error",
    TXT_NOTIFICATION_ERROR_CONTENT: "Invalid information",
    TXT_CONFIRM_ACTIVE_USER: "Confirm active user",
    TXT_DELETE_FAIL: "An error occurred. Cannot delete Branch",
    TXT_INACTIVE_ERROR: "The user you selected is already in the Inactive state. Please choose another user",
    TXT_ACTIVE_ERROR: "The user you selected is already in the Active state. Please choose another user",
    TXT_INVALID_DATE: "Invalid date. Please choose again.",
    TXT_DATE_NULL: "From Date field and To Date field are not null",
    TXT_UPDATE_ERROR: "An error occurred during execution. Please try again.",
    TXT_UPDATE_SUCCESS: "Successfully updated.",
    TXT_EMAIL_WRONG: "Email address is not valid. Please check again.",
    TXT_UPLOAD_DUPLICATE_DATA: "Duplicate data. Please check again!",
    TXT_UPLOAD_MAX_USER_WRONG: "The list has exceeded the number of registered people, please check again!",
    TXT_CONFIRM_CANCEL_CREATE_BRANCH: "Are you sure to cancel the process of creating a new Branch?",
    TXT_OF: "of",
    TXT_MORE_THAN: "more than",
    TXT_FAILURE_VALIDATION: "The data submitted is invalid. Please check again.",
    TXT_ADD_USER_SUCCESS: "Add new user successfully",
    TXT_SYSTEM_ERROR_LIST: "System errors",
    TXT_USER_ACTIVITY_LIST: "User activities",
    TXT_VERIFY_SMART_OTP_CONTENT:
      "We have sent the verification code to your phone number. Please enter the verification code to continue logging in.",
    TXT_RESEND_OTP: "Resend OTP code",
    TXT_VERIFY_OTP: "Verify OTP code",
    TXT_SMART_OTP_RETRY: "Incorrect SMART OTP code.\nYou have {{retry}} time(s) left to enter SMART OTP",
    TXT_SMART_OTP_BLOCK:
      "You have entered more than the allowed number of times.\nYou will be re-entered the OTP code after 5 minutes",
    TXT_SMART_OTP_EXPIRED: "Your OTP code has expired",
    TXT_SMART_OTP_LIMIT_ERROR: "The current OTP code is still valid.",
    TXT_GRANT_ADMIN_PERMISSION: "Grant administrator permission",
    TXT_REMOVE_ADMIN_PERMISSION: "Remove administrator permission",
    TXT_GENERATE_NEW_ADMIN_PASSWORD: "Generate new administrator's password",
    TXT_NEW_ADMIN_PASSWORD: "New administrator's password is: {{password}}",
    TXT_CONFIRM_ACTION: "Are you sure you want to perform this action?",
    TXT_LOGIN_EXPIRED: "Login session has expired",
    TXT_FILL_FORM: "Fill Form",
    TXT_UPLOAD_FILE: "Upload File",
    TXT_ADD_NEW: "Add user",
    TXT_USER: "User Name",
    TXT_UNIT: "Unit",
    TXT_DEPARTMENT: "Department",
    TXT_POSITION: "Position",
    TXT_STATUS: "Status",
    TXT_DOWNLOAD_SAMPLE_FILE: "Download sample file",
    TXT_IMPORT_SUCCESS: "Upload file successfully",
    TXT_IMPORT_SUCCESS_MSG: "User will be receive the invitation into the branch server in their emails",
    TXT_IMPORT_FAILURE: "Upload file failure",
    TXT_IMPORT_FAILURE_MSG:
      "This file cannot upload because of the wrong information\n Please download the description file to edit",
    TXT_FILE_NOT_VALID: "File is invalid",
    TXT_CHOOSE_ANOTHER_FILE_MSG: "Please upload another file",
    TXT_TITLE_UNIT_LIST: "Unit/Department List",
    TXT_CHOOSE_UNIT: "Choose unit",
    TXT_CHOOSE_DEPARTMENT: "Choose department",
    TXT_CHOOSE_POSITION: "Choose position",
    TXT_POSITION_NAME: "Position name",
    TXT_UNIT_NAME: "Unit name",
    TXT_DEPARTMENT_NAME: "Department name",
    TXT_CREATED_AT: "Created at",
    TXT_CREATE_POSITION_TITLE: "Create new position",
    TXT_EDIT_POSITION_TITLE: "Update position information",
    TXT_FROM_UNIT: "From Unit",
    TXT_FROM_DEPARTMENT: "From Department",
    TXT_CREATE_DEPARTMENT: "Create department",
    TXT_MEMBER_MANAGEMENT: "Member management",
    TXT_CREATE_CHANNEL: "Create channel",
    TXT_DELETE: "Delete",
    TXT_CREATE_UNIT: "Create unit",
    TXT_EDIT_UNIT: "Edit unit",
    TXT_ENTER_UNIT_NAME: "Enter unit name",
    TXT_CONFIRM_DELETE_UNIT: "Deleting a unit will also delete all affiliated departments. Are you sure to do it?",
    TXT_CONFIRM_DELETE_DEPARTMENT:
      "Deleting a department will also delete all affiliated positions. Are you sure to do it?",
    TXT_CREATE_DEPARTMENT_TITLE: "Create channel",
    TXT_EDIT_DEPARTMENT: "Edit department",
    TXT_ENTER_DEPARTMENT_NAME: "Enter department name",
    TXT_MEMBER_LIST: "Member list",
    TXT_ADD_MEMBER: "Add member",
    TXT_CREATE_POSITION_SUCCESS: "Create position successfully",
    TXT_UPDATE_POSITION_SUCCESS: "Update position successfully",
    TXT_DELETE_POSITION_SUCCESS: "Delete position successfully",
    TXT_CONFIRM_DELETE_POSITION: "Do you want to delete this positions?",
    TXT_CREATE_UNIT_SUCCESS: "Create unit successfully",
    TXT_UPDATE_UNIT_SUCCESS: "Update unit successfully",
    TXT_CREATE_UNIT_FAIL: "Create unit failure",
    TXT_UNIT_DUPLICATED: "Unit name is duplicated",
    TXT_INVALID_UNIT_NAME: "Unit name is invalid",
    TXT_MISSING_FORM_PARAMS: "Missing form parameters",
    TXT_NO_DEPARTMENTS: "No department found",
    TXT_ADD_MEMBER_DIALOG: "Add member",
    TXT_CONFIRM_DELETE_MEMBER: "Do you want to delete this member?",
    TXT_NO_UNITS: "No unit found",
    TXT_UNIT_CONTAIN_DEPARTMENTS: "This unit cannot be deleted, it has affiliated departments",
    TXT_DELETE_UNIT_SUCCESS: "Delete unit successfully",
    TXT_CREATE_DEPARTMENT_SUCCESS: "Create department successfully",
    TXT_UPDATE_DEPARTMENT_SUCCESS: "Update department successfully",
    TXT_CREATE_DEPARTMENT_FAIL: "Create department failure",
    TXT_DEPARTMENT_DUPLICATED: "Department name duplicated",
    TXT_INVALID_DEPARTMENT_NAME: "Department name invalid",
    TXT_DEPARTMENT_CONTAIN_POSITIONS: "This department cannot be deleted, it has a subordinate position",
    TXT_DELETE_DEPARTMENT_SUCCESS: "Delete department successfully",
    TXT_CREATE_CHANNEL_SUCCESS: "Create channel successfully",
    TXT_CREATE_CHANNEL_FAIL: "Create channel failure",
    TXT_CHANNEL_DUPLICATED: "Channel is existed",
    TXT_ADD_MEMBER_SUCCESS: "Add member successfully",
    TXT_DELETE_MEMBER_SUCCESS: "Delete member successfully",
    TXT_PHONE_DUPLICATED: "Phone number is existed. The invitation has been sent back.",
    TXT_DATA_WRONG_FORMAT: "The submitted data is in wrong format",
    TXT_CREATE_ACCOUNT_SUCCESS: "Create account successfully",
    TXT_CREATE_ACCOUNT_FAIL: "Create account failure",
    TXT_INVITE_SUCCESS: "The invitation is sent",
    TXT_INVITE_FAIL: "The invitation sent failed",

    // FORMATS
    FM_REMOVE_SINGLE_PERSON: "Disable {{name}} from this server?",
    FM_REMOVE_MULTIPLE_PEOPLE: "Disable {{number}} people from this server?",
    FM_ACTIVE_SINGLE_PERSON: "Do you want active {{name}}?",
    FM_ACTIVE_MULTIPLE_PERSON: "Do you want active {{number}} users?",
    FM_UPLOAD_PHONE_FORMAT_WRONG: "{{number}} user(s) has(have) wrong phone number format, please check again!",
    FM_COUNTDOWN_OTP: "(00:{{ time }})",
  },
};

export default en;
