import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseOutlined, EmojiEmotionsOutlined, AccessAlarmOutlined } from "@mui/icons-material";
import { LocalizationProvider, DateCalendar, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EmojiPicker from "emoji-picker-react";
import { format } from "date-fns";
import { useBotMessageUpdate } from "hooks";
import { SystemUtils, CommonUtils } from "utils";
import { SystemConstant } from "const";
import ConfirmDialog from "./ConfirmDialog";

const EditMessageDialog = ({ message, open, onClose, refetchList }) => {
  const classes = useStyles();
  const textAreaRef = useRef(null);

  const limitContentLength = 500;

  const { updateBotMessage } = useBotMessageUpdate();

  const [content, setContent] = useState(message?.content);
  const [time, setTime] = useState(message.time && message.time > 0 ? message.time : Date.now());
  const [isOpenEmoji, setOpenEmoji] = useState(false);
  const [isOpenEditTime, setIsOpenEditTime] = useState(false);
  const [isDisableSchedule, setIsDisableSchedule] = useState(true);
  const [isOpenConfirmExit, setIsOpenConfirmExit] = useState(false);
  const [isOpenConfirmDraft, setIsOpenConfirmDraft] = useState(false);
  const [msgError, setMsgError] = useState("");

  const handleChangeForm = event => {
    setContent(event.target.value);
  };

  const handleSendNow = () => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification("Nội dung tin nhắn không được trống!", "error");
    } else {
      updateBotMessage({
        bot_message_id: message.id,
        content: content,
        time: 0,
        status: SystemConstant.BOT_MESSAGE_STATUS.SUCCESS.status,
        onClose: async () => {
          SystemUtils.appendNotification("Gửi tin nhắn thành công", "success");
          onClose();
          refetchList();
        },
      });
    }
  };

  const handleDraft = () => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification("Nội dung tin nhắn không được trống!", "error");
    } else {
      if (content.length >= limitContentLength) {
        SystemUtils.appendNotification("Nội dung tin nhắn vượt quá 500 ký tự!", "error");
      } else {
        updateBotMessage({
          bot_message_id: message.id,
          content: content,
          time: 0,
          status: SystemConstant.BOT_MESSAGE_STATUS.DRAFT.status,
          onClose: () => {
            onClose();
            refetchList();
          },
        });
      }
    }
  };

  const handleScheduleSend = () => {
    if (!Boolean(content)) {
      SystemUtils.appendNotification("Nội dung tin nhắn không được trống!", "error");
    } else {
      if (content.length >= limitContentLength) {
        SystemUtils.appendNotification("Nội dung tin nhắn vượt quá 500 ký tự!", "error");
      } else {
        updateBotMessage({
          bot_message_id: message.id,
          content: content,
          time: time,
          status: SystemConstant.BOT_MESSAGE_STATUS.PENDING.status,
          onClose: () => {
            onClose();
            refetchList();
          },
        });
      }
    }
  };

  const onEmojiClick = emojiObject => {
    const cursorPosition = textAreaRef.current.selectionStart;
    const textBeforeCursor = content.substring(0, cursorPosition);
    const textAfterCursor = content.substring(cursorPosition);
    setContent(textBeforeCursor + emojiObject.emoji + textAfterCursor);
  };

  const handleExit = () => {
    if (content && content.length > 0) {
      setIsOpenConfirmExit(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    setIsDisableSchedule(!Boolean(content));

    if (content && content.length >= limitContentLength) {
      setMsgError("Nhập tối đa 500 ký tự");
    } else {
      setMsgError("");
    }
  }, [content, time, isOpenEditTime]);

  useEffect(() => {
    setContent(message?.content);
    setTime(message.time && message.time > 0 ? message.time : Date.now());
    setOpenEmoji(false);
    setIsOpenEditTime(false);
    setIsOpenConfirmExit(false);
    setIsOpenConfirmDraft(false);
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <ConfirmDialog
        open={isOpenConfirmExit}
        onClose={onClose}
        onSubmit={handleDraft}
        title="Lưu bản nháp"
        content="Bạn có muốn lưu bản nháp không?"
        submit="Lưu"
        exit="Thoát"
      />

      <ConfirmDialog
        open={isOpenConfirmDraft}
        onClose={() => {
          setIsOpenConfirmDraft(false);
        }}
        onSubmit={handleDraft}
        title="Lưu bản nháp"
        content="Bạn có muốn lưu bản nháp không?"
        submit="Lưu"
        exit="Huỷ"
      />

      <Box sx={{ display: "flex", justifyContent: "space-between", height: "40px", backgroundColor: "#F2F6FC" }}>
        <DialogTitle sx={{ fontWeight: "bold", width: "88%", padding: "6px", paddingLeft: "40%" }}>
          {isOpenEditTime ? "Đặt lịch gửi tin nhắn" : "Soạn tin nhắn"}
        </DialogTitle>
        <Button onClick={handleExit}>
          <CloseOutlined fill="black" />
        </Button>
      </Box>
      {isOpenEditTime ? (
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                minDate={new Date(Date.now())}
                value={new Date(time)}
                onChange={e => {
                  setTime(e.getTime());
                }}
              />
            </LocalizationProvider>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField sx={{ paddingTop: "44px" }} disabled defaultValue={format(new Date(time), "dd/MM/yyyy")} />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  sx={{ paddingTop: "24px" }}
                  value={new Date(time)}
                  onChange={e => {
                    if (!isNaN(e) && e) {
                      setTime(e.getTime());
                    } else {
                      setIsDisableSchedule(true);
                    }
                  }}
                />
              </LocalizationProvider>

              <Box sx={{ display: "flex", gap: "12px", justifyContent: "flex-end", marginTop: "44%" }}>
                <Box
                  sx={{
                    height: "36.5px",
                    width: "44px",
                    backgroundColor: "#F5F5F5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setIsOpenEditTime(false)}
                >
                  Huỷ
                </Box>
                <Button variant="contained" color="primary" disabled={isDisableSchedule} onClick={handleScheduleSend}>
                  Đặt lịch gửi
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{ paddingTop: "6px" }}>
          <Box component="form" className={classes.form}>
            <TextareaAutosize
              className={content && content.length >= limitContentLength ? classes.textAreaError : classes.textArea}
              minRows="20"
              maxRows="25"
              onChange={handleChangeForm}
              onClick={() => setOpenEmoji(false)}
              value={content}
              ref={textAreaRef}
              placeholder="Nhập thông tin mô tả"
            />

            <Typography sx={{ color: "red" }}> {msgError} </Typography>

            <Box className={classes.buttonContainer}>
              <Box>
                <EmojiEmotionsOutlined sx={{ cursor: "pointer" }} onClick={() => setOpenEmoji(!isOpenEmoji)} />
                {isOpenEmoji && (
                  <Box sx={{ position: "fixed", bottom: "10%", zIndex: "1" }}>
                    <EmojiPicker emojiStyle="apple" emojiVersion="3.0" onEmojiClick={onEmojiClick} />
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "12px" }}>
                <Box
                  onClick={() => {
                    setIsOpenConfirmDraft(true);
                  }}
                  disabled={!Boolean(content)}
                  className={classes.btnDraft}
                >
                  Lưu bản nháp
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "4px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendNow}
                    disabled={!Boolean(content) || msgError.length > 0}
                    sx={{ borderRadius: "12px 0 0 12px" }}
                  >
                    Gửi
                  </Button>

                  <Box className={classes.iconTime} onClick={() => setIsOpenEditTime(true)}>
                    <AccessAlarmOutlined />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default EditMessageDialog;

const useStyles = makeStyles(() => ({
  form: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },

  textArea: {
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
  },

  textAreaError: {
    boxSizing: "border-box",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid red",
  },

  btnDraft: {
    height: "36.5px",
    width: "120px",
    border: "1px solid #DFDFDF",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontWeight: "bold",
  },

  iconTime: {
    borderRadius: "0 12px 12px 0",
    backgroundColor: "#1976d2",
    color: "white",
    height: "36.5px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));
