import React from "react";
import { Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SystemConstant } from "const";

const MessageStatus = ({ status }) => {
  const classes = useStyles();

  const StatusLabel = () => {
    switch (status) {
      case SystemConstant.BOT_MESSAGE_STATUS.PENDING.status:
        return (
          <Chip
            label={SystemConstant.BOT_MESSAGE_STATUS.PENDING.label}
            sx={{ backgroundColor: "#FFE600" }}
            className={classes.status}
          />
        );
      case SystemConstant.BOT_MESSAGE_STATUS.SUCCESS.status:
        return (
          <Chip label={SystemConstant.BOT_MESSAGE_STATUS.SUCCESS.label} color="success" className={classes.status} />
        );
      case SystemConstant.BOT_MESSAGE_STATUS.CANCELED.status:
        return (
          <Chip label={SystemConstant.BOT_MESSAGE_STATUS.CANCELED.label} color="error" className={classes.status} />
        );
      case SystemConstant.BOT_MESSAGE_STATUS.DRAFT.status:
        return (
          <Chip label={SystemConstant.BOT_MESSAGE_STATUS.DRAFT.label} color="warning" className={classes.status} />
        );
      default:
        return <Chip label={SystemConstant.BOT_MESSAGE_STATUS.FAILED.label} color="error" className={classes.status} />;
    }
  };

  return (
    <Box className={classes.container}>
      <StatusLabel />
    </Box>
  );
};

export default MessageStatus;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
  },

  status: {
    cursor: "default",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },
}));
