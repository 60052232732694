import React, { useState } from "react";
import { Box, Button, Chip, Dialog, DialogTitle, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SystemConstant } from "const";
import { useBranchRequestUpdate } from "hooks";
import FormUpdateBranchRequest from "./FormUpdateBranchRequest";

const BranchRequestStatus = ({ user, refetch }) => {
  const classes = useStyles();
  const status = user ? user.status : 0;

  const { updateBranchRequest } = useBranchRequestUpdate();

  const [isOpenConfirmCancleReject, setIsOpenConfirmCancleReject] = useState(false);
  const [isOpenConfirmReject, setIsOpenConfirmReject] = useState(false);
  const [isOpenFormAccept, setIsOpenFormAccept] = useState(false);

  const handleCancleReject = () => {
    updateBranchRequest({
      branch_request_ids: [user.id],
      status: SystemConstant.BRANCH_REQUEST_STATUS.PENDING.status,
      refetch: refetch,
      onClose: () => {
        setIsOpenConfirmCancleReject(false);
      },
    });
  };

  const handleReject = () => {
    updateBranchRequest({
      branch_request_ids: [user.id],
      status: SystemConstant.BRANCH_REQUEST_STATUS.REJECTED.status,
      refetch: refetch,
      onClose: () => {
        setIsOpenConfirmReject(false);
        setIsOpenFormAccept(false);
      },
    });
  };

  const onCloseForm = () => {
    setIsOpenFormAccept(false);
  };

  const DialogConfirmCancleReject = () => {
    return (
      <Dialog open={isOpenConfirmCancleReject} maxWidth="sm" fullWidth>
        <DialogTitle className={classes.dialogTitle}>Xác nhận gỡ trạng thái từ chối</DialogTitle>
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          Bạn có muốn gỡ trạng thái từ chối của {user.phone} không?
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{ width: "70%", marginBottom: "8px" }}
            onClick={handleCancleReject}
          >
            Xác nhận
          </Button>
          <Button
            sx={{ width: "70%", marginBottom: "8px", color: "#CBCACA" }}
            onClick={() => {
              setIsOpenConfirmCancleReject(false);
            }}
          >
            Huỷ
          </Button>
        </Box>
      </Dialog>
    );
  };

  const DialogConfirmReject = () => {
    return (
      <Dialog open={isOpenConfirmReject} maxWidth="sm" fullWidth>
        <DialogTitle className={classes.dialogTitle}>Từ chối yêu cầu tham gia</DialogTitle>
        <Typography sx={{ display: "flex", justifyContent: "center" }}>
          Bạn có muốn từ chối yêu cầu tham gia của {user.phone} không?
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button variant="contained" color="error" sx={{ width: "70%", marginBottom: "8px" }} onClick={handleReject}>
            Xác nhận
          </Button>
          <Button
            sx={{ width: "70%", marginBottom: "8px", color: "#CBCACA" }}
            onClick={() => {
              setIsOpenConfirmReject(false);
            }}
          >
            Huỷ
          </Button>
        </Box>
      </Dialog>
    );
  };

  const StatusLabel = () => {
    switch (status) {
      case SystemConstant.BRANCH_REQUEST_STATUS.PENDING.status:
        return (
          <Chip
            label={SystemConstant.BRANCH_REQUEST_STATUS.PENDING.label}
            color="warning"
            sx={{ border: "1px solid #FFD402", color: "#FFD402" }}
            variant="outlined"
            className={classes.status}
            onClick={() => {
              setIsOpenFormAccept(true);
            }}
          />
        );
      default:
        return (
          <Chip
            label={SystemConstant.BRANCH_REQUEST_STATUS.REJECTED.label}
            color="error"
            variant="outlined"
            className={classes.status}
            onClick={() => {
              setIsOpenConfirmCancleReject(true);
            }}
          />
        );
    }
  };

  return (
    <Box className={classes.container}>
      <StatusLabel />
      <DialogConfirmCancleReject />
      <FormUpdateBranchRequest
        user={user}
        isOpenFormAccept={isOpenFormAccept}
        onCloseForm={onCloseForm}
        onOpenConfirm={() => setIsOpenConfirmReject(true)}
        refetch={refetch}
      />
      <DialogConfirmReject />
    </Box>
  );
};

export default BranchRequestStatus;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  status: {
    cursor: "pointer",
    width: "130px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    fontWeight: "bold",
  },

  infoField: {
    margin: "10px 0px",
  },

  datePicker: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
