import React, { Fragment, useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, Typography, MenuItem } from "@mui/material";
import { CalendarMonthOutlined, ArrowDropDownOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonUtils, StorageUtils, DatetimeUtils } from "utils";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useStatisticUserGet } from "hooks";
import { KeyConstant } from "const";
import SelectDateTime from "./SelectDateTime";
import UserListDialog from "./UserListDialog";

const UserStatistic = ({ unitList }) => {
  const classes = useStyles();
  const { getStatisticUser } = useStatisticUserGet();
  const branchName = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_NAME);

  const [unitId, setUnitId] = useState(0);
  const [labelCalendar, setLabelCalendar] = useState("Hôm nay");
  const [labelChart, setLabelChart] = useState(branchName);
  const [dataChartUser, setDataChartUser] = useState("");
  const [dataChartActive, setDataChartActive] = useState("");
  const [optionsChartUser, setOptionsChartUser] = useState("");
  const [optionsChartActive, setOptionsChartActive] = useState("");
  const [totalUser, setTotalUser] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [sinceTime, setSinceTime] = useState(CommonUtils.getStartToday());
  const [lastTime, setLastTime] = useState(null);
  const [openDateTime, setOpenDateTime] = useState(false);

  const [openTableOfUnit, setOpenTableOfUnit] = useState(false);
  const [openTableOfDepartment, setOpenTableOfDepartment] = useState(false);
  const [openTableOfActive, setOpenTableOfActive] = useState(false);
  const [openTableOfPending, setOpenTableOfPending] = useState(false);
  const [choosedElementId, setChoosedElementId] = useState("");
  const [titleDataTable, setTitleDataTable] = useState("");

  const onChangeUnit = e => {
    setUnitId(e.target.value);
  };

  const closeDialogDataTable = () => {
    setChoosedElementId(null);
    setOpenTableOfUnit(false);
    setOpenTableOfDepartment(false);
    setOpenTableOfActive(false);
    setOpenTableOfPending(false);
    setTitleDataTable("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {};
      if (unitId !== 0) {
        payload.unit_id = unitId;
      }
      if (sinceTime) {
        payload.since_time = sinceTime;
      }
      if (lastTime) {
        payload.last_time = lastTime;
      }
      const response = await getStatisticUser(payload);
      if (response) {
        const label = response.data.data.map(item => item.name);
        const data = response.data.data.map(item => item.members);
        if (unitId === 0) {
          setLabelChart(branchName);
        } else {
          setLabelChart(unitList.find(item => item.unitId === unitId).unitName);
        }

        setTotalUser(data.reduce((sum, element) => sum + element, 0));
        setTotalElement(response.data.data.filter(item => item.id !== null).length);
        setTotalActive(response.data.count_active + response.data.count_pending);

        const dataSetUser = {
          labels: label,
          datasets: [
            {
              label: "Số lượng",
              data: data,
              backgroundColor: label.map(() => CommonUtils.getRandomColor()),
              hoverOffset: 4,
            },
          ],
        };
        setDataChartUser(dataSetUser);

        const dataSetActive = {
          labels: ["Đã tham gia", "Chưa tham gia"],
          datasets: [
            {
              label: "Số lượng",
              data: [response.data.count_active || 0, response.data.count_pending || 0],
              backgroundColor: ["#57BEB5", "#3070F5"],
              hoverOffset: 4,
            },
          ],
        };
        setDataChartActive(dataSetActive);

        const optionsDataUser = {
          cutout: "70%",
          radius: "80%",
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              maxWidth: 220,
              labels: {
                usePointStyle: false,
                boxWidth: 15,
                boxHeight: 15,
              },
            },
          },
          onClick: (e, activeEls) => {
            const dataIndex = activeEls[0]?.index;
            const id = response.data.data[dataIndex]?.id;
            const name = response.data.data[dataIndex]?.name;

            setChoosedElementId(id);
            setTitleDataTable(name);
            if (unitId === 0) {
              setOpenTableOfUnit(true);
            } else {
              setOpenTableOfDepartment(true);
            }
          },
        };
        setOptionsChartUser(optionsDataUser);

        const optionsDataActive = {
          cutout: "70%",
          radius: "80%",
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              maxWidth: 220,
              labels: {
                usePointStyle: false,
                boxWidth: 15,
                boxHeight: 15,
              },
            },
          },
          onClick: (e, activeEls) => {
            const dataIndex = activeEls[0]?.index;
            setChoosedElementId("");
            if (dataIndex == 0) {
              setOpenTableOfActive(true);
            } else {
              setOpenTableOfPending(true);
            }
          },
        };
        setOptionsChartActive(optionsDataActive);
      }
    };

    fetchData();
  }, [unitId, sinceTime, lastTime]);

  return (
    <Fragment>
      <Box className={classes.heading}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}> Tổng quan người dùng </Typography>
        <Box sx={{ display: "flex", gap: "12px", position: "relative" }}>
          <FormControl sx={{ ml: 1, width: 200 }}>
            <InputLabel>Phân loại</InputLabel>
            <Select size="small" onChange={onChangeUnit} value={unitId} label="Phân loại">
              {unitList.map(data => (
                <MenuItem key={data.unitId} value={data.unitId}>
                  {data.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            className={classes.calendar}
            onClick={() => {
              setOpenDateTime(!openDateTime);
            }}
          >
            <CalendarMonthOutlined />
            <Typography> {labelCalendar} </Typography>
            <ArrowDropDownOutlined />
          </Box>
          <Box sx={{ position: "absolute", zIndex: 4, top: "42px", right: "0px" }}>
            <SelectDateTime
              open={openDateTime}
              setLabel={setLabelCalendar}
              onClose={() => setOpenDateTime(false)}
              setSinceTime={setSinceTime}
              setLastTime={setLastTime}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.chartParent}>
        <Box className={classes.chartChild}>
          <Typography className={classes.chartLabel}>Số người sử dụng trong {labelChart}</Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "450px" }}>
              {Boolean(dataChartUser) && <Doughnut data={dataChartUser} options={optionsChartUser} />}
            </Box>
            <Box className={classes.textInnerChart}>
              <Typography>Tổng số người dùng</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{totalUser}</Typography>
              <Typography>Tổng số {unitId === 0 ? "đơn vị" : "phòng ban"}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{totalElement}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.chartChild}>
          <Typography className={classes.chartLabel}>Trạng thái người dùng</Typography>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "450px" }}>
              {Boolean(dataChartActive) && <Doughnut data={dataChartActive} options={optionsChartActive} />}
            </Box>
            <Box className={classes.textInnerChart}>
              <Typography>Tổng số người dùng</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{totalActive}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ color: "#A8A8A8" }}>*Dữ liệu cập nhật mới nhất vào 00:01 mỗi ngày</Typography>

      {openTableOfUnit && (
        <UserListDialog
          open={openTableOfUnit}
          unitId={choosedElementId}
          title={titleDataTable}
          onClose={closeDialogDataTable}
          sinceTime={sinceTime}
          lastTime={lastTime}
          joinStatus={1}
          includeUnit={Boolean(choosedElementId) ? true : false}
          includeDepartment={true}
        />
      )}

      {openTableOfDepartment && (
        <UserListDialog
          open={openTableOfDepartment}
          unitId={unitId}
          departmentId={choosedElementId}
          title={titleDataTable}
          onClose={closeDialogDataTable}
          sinceTime={sinceTime}
          lastTime={lastTime}
          joinStatus={1}
          includeUnit={unitId !== -1 ? true : false}
          includeDepartment={Boolean(choosedElementId) ? true : false}
        />
      )}

      {openTableOfActive && (
        <UserListDialog
          open={openTableOfActive}
          unitId={unitId}
          title={labelChart}
          onClose={closeDialogDataTable}
          sinceTime={sinceTime}
          lastTime={lastTime}
          joinStatus={1}
          includeUnit={unitId !== -1 ? true : false}
          includeDepartment={unitId !== -1 ? true : false}
        />
      )}

      {openTableOfPending && (
        <UserListDialog
          open={openTableOfPending}
          unitId={unitId}
          title={labelChart}
          onClose={closeDialogDataTable}
          sinceTime={sinceTime}
          lastTime={lastTime}
          joinStatus={3}
          includeUnit={unitId !== -1 ? true : false}
          includeDepartment={unitId !== -1 ? true : false}
        />
      )}
    </Fragment>
  );
};

export default UserStatistic;

const useStyles = makeStyles(() => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    borderBottom: "1px solid #DFDFDF",
  },

  calendar: {
    minWidth: "200px",
    height: "40px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    justifyContent: "space-between",
    cursor: "pointer",
    maxWidth: "250px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },

  chartParent: {
    height: 550,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  chartChild: {
    width: "45%",
    height: "90%",
    border: "1px solid #DFDFDF",
    borderRadius: "12px",
    padding: "16px",
    position: "relative",
  },

  chartLabel: {
    fontWeight: "bold",
    fontSize: "16px",
  },

  textInnerChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "40%",
  },
}));
