export const KEY_LANGUAGE = "trios_lang";

export const KEY_ACCESS_TOKEN = "trios_access_token";

export const KEY_USER_ID = "trios_user_id";

export const KEY_USERNAME = "trios_username";

export const KEY_OLD_PASSWORD = "trios_old_pass";

export const KEY_FIRST_LOGIN = "trios_first_login";

export const KEY_BRANCH_ATTACHMENT = "trios_branch_attachment";

export const KEY_BRANCH_ID = "trios_branch_id";

export const KEY_BRANCH_NAME = "trios_branch_name";

export const KEY_BRANCH_TYPE = "trios_branch_type";

export const KEY_BRANCH_MODE = "trios_branch_mode";

export const KEY_LOGIN_MODE = "trios_login_mode";

export const KEY_BRANCH_LIST = "trios_branch_list";

export const KEY_SELECTED_BRANCH = "trios_selected_branch";

export const KEY_CURRENT_DOMAIN = "trios_current_domain";

export const KEY_REMEMBER_KEY = "trios_is_remember";

export const KEY_SMART_OTP_CONFIGS = "trios_smart_otp";

export const KEY_PRE_AUTH = "trios_pre_auth";

export const KEY_ADMIN_ROLE = "trios_admin_role";

export const KEY_SMART_OTP_REMOTE_CODE = "trios_smart_otp_remote_code";

export const KEY_CACHED_BRANCH_AVATAR_BLOB = "trios_cached_branch_avatar_blob_";

export const KEY_BRANCH_INFORMATION = "trios_branch_information";

export const KEY_BRANCH_QR_EXPIRY_TIME = "trios_branch_qr_expiry_time";

export const KEY_BOT_ACCOUNT_ID = "trios_bot_account_id";

export const KEY_TUS_DOMAIN = "trios_tus_domain";
