import { LangConstant } from "const";

export const WEB_ADMIN_MODE = 1;

export const WEB_ADMIN_LOGIN_MODE = 2;

export const WEB_ADMIN_VERIFY_MODE = 2;

export const ACTIVE_PAGINATION = 1;

export const ACTIVE_ASC = 1;

export const USERS_LIMITED_NUMBER = 10;

export const GET_USER_LAST_TIME_VALUE = 1;

export const DEFAULT_OFFSET_NUMBER = 0;

export const DEFAULT_NAME = "unknown";

export const USER_STATUS = {
  inactive: 0,
  joined: 1,
  deleted: 2,
  pending: 3,
  refused: 4,
};

export const GLOBAL_TYPE = 0;

export const ACTIVE_STATUS = { inactive: 0, active: 1 };

export const BRANCH_ACTIVE_STATUS = { inactive: 0, active: 1 };

export const USER_ACTIVE_STATUS = { inactive: 0, active: 1 };

export const ERROR_TYPE = {
  phoneFormat: 0,
  maxUser: 1,
  duplicate: 2,
};

export const BRANCH_ERROR_TYPE = {
  sendMessage: 0,
  audioCall: 1,
  videoCall: 2,
  audioGroupCall: 3,
  videoGroupCall: 4,
  updateProfile: 5,
};

export const OTP_TYPE = {
  smsOtp: 0,
  smartOtp: 2,
};

export const DEFAULT_OTP_LENGTH = 6;

export const SYSTEM_TRACKING_TYPE = {
  errorsList: 0,
  userActivities: 1,
};

export const SMART_OTP_CONFIGS = {
  expireAfter: 60 * 1000, // milliseconds
  blockTime: 5 * 60 * 1000, // milliseconds
  maxRetry: 4,
  length: 6,
};

export const LOGIN_ROLE = {
  ROOT: 0,
  ADMIN: 1,
};

export const LOGGER_ACTION_TYPES = {
  LOGIN: 0,
  UPDATE_BRANCH_INFORMATION: 1,
  ADD_NEW_USERS: 2,
  ACTIVE_USER: 3,
  BLOCK_USER: 4,
  UPDATE_USER_INFORMATION: 5,
  RESET_USER_PASSWORD: 6,
  CHANGE_PASSWORD: 7,
  UPDATE_BRANCH_CONFIGS: 8,
  IMPORT_NEW_USERS_FROM_EXCEL: 9,
  LOGOUT: 10,
  ADD_BRANCH: 11,
  DELETE_BRANCH: 12,
  ACTIVATE_BRANCH: 13,
  DEACTIVATE_BRANCH: 14,
  CREATE_UNIT: 15,
  EDIT_UNIT: 16,
  DELETE_UNIT: 17,
  CREATE_DEPARTMENT: 18,
  EDIT_DEPARTMENT: 19,
  DELETE_DEPARTMENT: 20,
  ADD_DEPARTMENT_USERS: 21,
  REMOVE_DEPARTMENT_USERS: 22,
  CREATE_UNIT_CHANNEL: 23,
  CREATE_DEPARTMENT_CHANNEL: 24,
  CREATE_POSITION: 25,
  EDIT_POSITION: 26,
  DELETE_POSITION: 27,
};

export const UNIT_ERROR = {
  MISSING_PARAMETERS: 0,
  IN_WRONG_FORMAT: 1,
  DUPLICATED: 2,
  CONTAIN_DEPARTMENTS: 3,
};

export const DEPARTMENT_ERROR = {
  MISSING_PARAMETERS: 0,
  IN_WRONG_FORMAT: 1,
  DUPLICATED: 2,
  CHANNEL_MISSING_PARAMETERS: 3,
  CHANNEL_DUPLICATED: 4,
  CONTAIN_POSITIONS: 5,
};

export const USER_ERROR = {
  MISSING_PARAMETERS: 0,
  IN_WRONG_FORMAT: 1,
  DUPLICATED: 2,
};

export const POSITION_ERROR = {
  MISSING_PARAMETERS: 0,
  IN_WRONG_FORMAT: 1,
  DUPLICATED: 2,
};

export const CHANNEL_MODE = {
  public: { label: LangConstant.L_PUBLIC_MODE, code: 0 },
  private: { label: LangConstant.L_PRIVATE_MODE, code: 1 },
};

export const GROUP_TYPE = {
  INBOX: 0,
  GROUP: 1,
  CHANNEL: 2,
};

export const BRANCH_REQUEST_STATUS = {
  ALL: {
    label: "Tất cả",
    status: -1,
  },
  PENDING: {
    label: "Chờ phê duyệt",
    status: 0,
  },
  ACCEPTED: {
    label: "Đã chấp nhận",
    status: 1,
  },
  REJECTED: {
    label: "Đã từ chối",
    status: 2,
  },
};

export const BOT_MESSAGE_STATUS = {
  PENDING: {
    label: "Đã đặt lịch gửi",
    status: 0,
  },
  SUCCESS: {
    label: "Đã gửi",
    status: 1,
  },
  CANCELED: {
    label: "Đã huỷ",
    status: 2,
  },
  DRAFT: {
    label: "Bản nháp",
    status: 3,
  },
  FAILED: {
    label: "Gửi thất bại",
    status: 4,
  },
};

export const STATE = {
  INACTIVE: 0,
  ACTIVE: 1,
  DELETED: 2,
  PENDING: 3,
  REJECT: 4,
};

export const BOT_ACCOUNT_PHONE = "bot@bglobal.com";

export const GROUP_TYPE_NAME = {
  0: "Tin nhắn riêng",
  1: "Nhóm",
  2: "Kênh",
};
