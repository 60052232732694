import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";

export default function useDeleteUser() {
  const { t: getLabel } = useTranslation();
  const { invalidateUserQueries } = useInvalidateQueries();

  const deleteUserMutation = useMutation({
    mutationFn: async payload => {
      payload.users.map(item => (item.state = 0));
      const apiPayload = HumpUtils.decamelizeKeys({
        branchAccounts: JSON.stringify(HumpUtils.decamelizeKeys(payload?.users)),
      });
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_USER_INFO,
        qs.stringify(apiPayload),
      );
      return response.data;
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        SystemUtils.appendNotification(getLabel("TXT_DELETE_MEMBER_SUCCESS"), "success");
        await invalidateUserQueries();
        onClose();
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const deleteUser = payload => {
    deleteUserMutation.mutate(payload);
  };

  return { deleteUser };
}
