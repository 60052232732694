import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Clear } from "@mui/icons-material";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { LangConstant, SystemConstant } from "const";
import { CommonUtils } from "utils";
import { useCreateUser, useDepartmentDetails, useUnitDetails, useUnitList, useUpdateUserAccount } from "hooks";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const UserInfoDialog = ({ data, isOpen, isEdit, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { updateUserAccount } = useUpdateUserAccount();
  const { createUser } = useCreateUser();
  const { totalUnitList } = useUnitList();
  const { positions: positionsByUnit, departments, handleGetUnitDetails } = useUnitDetails();
  const { positions: positionsByDepartment, handleGetDepartmentDetails } = useDepartmentDetails();

  const userDetail = getLabel(LangConstant.OBJ_USER_DETAIL, { returnObjects: true });

  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [unit, setUnit] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [joinStatus, setJoinStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [dialogContent, setDialogContent] = useState(null);
  const [positions, setPositions] = useState([]);
  const [dataBirthday, setDataBirthday] = useState("");

  const isCreate = data === null && isEdit === true && isOpen === true;
  const isShowInfo = Boolean(data) && isEdit === false && isOpen === true;

  const handleChangeName = event => {
    setName(event.target.value);
  };

  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };

  const handleChangeBirthday = event => {
    setBirthday(format(event, "dd-MM-yyyy"));
  };

  const handleChangePhone = event => {
    setPhone(event.target.value.replace(/\D/g, ""));
  };

  const handleChangeUnit = event => {
    setUnit(event.target.value);
    setDepartment("");
    setPosition("");
    handleGetUnitDetails(event.target.value);
  };

  const handleChangeDepartment = event => {
    setDepartment(event.target.value);
    setPosition("");
    handleGetDepartmentDetails(event.target.value);
  };

  const handleChangePosition = event => {
    setPosition(event.target.value);
  };

  const handleEditUser = () => {
    if (Boolean(email) && email !== "" && (!CommonUtils.validateEmail(email) || email.length > 255)) {
      setDialogContent(getLabel(LangConstant.TXT_EMAIL_WRONG));
    } else {
      updateUserAccount({
        ...data,
        name: name,
        mail: email,
        birthday: birthday,
        unitId: unit,
        departmentId: department,
        positionId: position,
        onClose: onClose,
      });
    }
  };

  const handleCreateUser = () => {
    if (Boolean(email) && email !== "" && (!CommonUtils.validateEmail(email) || email.length > 255)) {
      setDialogContent(getLabel(LangConstant.TXT_EMAIL_WRONG));
    } else if (Boolean(name) && name !== "" && name.length > 255) {
      setDialogContent(getLabel("TXT_DATA_WRONG_FORMAT"));
    } else {
      createUser({
        ...data,
        name: name,
        mail: email,
        birthday: birthday,
        phone: parsePhoneNumber(phone, "VN").number,
        unitId: unit,
        departmentId: department,
        positionId: position,
        onClose: onClose,
      });
    }
  };

  const handleCloseNotification = () => {
    setDialogContent(null);
  };

  /**
   * Check Join Status
   */
  useEffect(() => {
    if (data) {
      setEmail(data.email);
      setBirthday(data.birthday);
      setName(data.name);
      setPhone(data.phone);
      setUnit(data.unitId || "");
      setDepartment(data.departmentId || "");
      setPosition(data.positionId || "");

      if (data.unitId) handleGetUnitDetails(data.unitId);
      if (data.departmentId) handleGetDepartmentDetails(data.unitId);

      switch (data.state) {
        case SystemConstant.USER_STATUS.joined:
          setJoinStatus(getLabel(LangConstant.L_JOINED_STATUS));
          break;
        case SystemConstant.USER_STATUS.pending:
          setJoinStatus(getLabel(LangConstant.L_PENDING_STATUS));
          break;
        default:
          setJoinStatus(getLabel(LangConstant.L_REFUSED_STATUS));
          break;
      }

      switch (data.branchAccountState) {
        case SystemConstant.ACTIVE_STATUS.active:
          setActiveStatus(getLabel(LangConstant.L_ACTIVE_USER));
          break;
        default:
          setActiveStatus(getLabel(LangConstant.L_INACTIVE_USER));
          break;
      }
    } else {
      setEmail("");
      setBirthday("");
      setName("");
      setPhone("");
      setUnit("");
      setDepartment("");
      setPosition("");
      setJoinStatus(getLabel(LangConstant.L_REFUSED_STATUS));
      setActiveStatus(getLabel(LangConstant.L_INACTIVE_USER));
    }
  }, [data]);

  useEffect(() => {
    if (positionsByUnit) {
      let newListPos = positionsByUnit.filter(item => item.departmentId === department);
      setPositions(newListPos);
    }
  }, [positionsByUnit]);

  useEffect(() => {
    if (positionsByDepartment) setPositions(positionsByDepartment);
  }, [positionsByDepartment]);

  useEffect(() => {
    if (birthday) {
      const [day, month, year] = birthday.split("-").map(Number);
      setDataBirthday(new Date(year, month - 1, day));
    }
  }, [birthday]);

  return (
    <Fragment>
      <Dialog open={isOpen} classes={{ paper: classes.component }}>
        <DialogTitle className={classes.title}>
          <Typography className={classes.titleName}>
            {getLabel(isCreate ? LangConstant.TXT_ADD_NEW_USER : LangConstant.TXT_DETAILS_INFO)}
          </Typography>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.userContent}>
          <TextField
            label={userDetail.fullName}
            fullWidth
            defaultValue={data?.name || ""}
            className={classes.infoField}
            onChange={handleChangeName}
            InputProps={{
              disabled: !isEdit,
              classes: { input: classes.infoValue },
            }}
            name="full-name"
            required
          />
          <TextField
            label={userDetail.phone}
            fullWidth
            required
            defaultValue={data?.phone || ""}
            className={classes.infoField}
            value={phone}
            onChange={handleChangePhone}
            InputProps={{
              disabled: isCreate === false,
              classes: { input: classes.infoValue },
            }}
            name="phone"
          />
          <TextField
            label={userDetail.email}
            fullWidth
            type="email"
            defaultValue={data?.mail || ""}
            className={classes.infoField}
            onChange={handleChangeEmail}
            InputProps={{
              disabled: !isEdit,
              classes: { input: classes.infoValue },
            }}
            required
            name="email"
          />

          <Box className={classes.datePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ with: "100%" }}>
              <DatePicker
                label={userDetail.birthday}
                fullWidth
                value={dataBirthday}
                InputLabelProps={{ shrink: true }}
                format="dd/MM/yyyy"
                className={classes.infoField}
                InputProps={{
                  disabled: !isEdit,
                  classes: { input: classes.infoValue },
                }}
                name="birthday"
                onChange={e => {
                  handleChangeBirthday(e);
                }}
              />
            </LocalizationProvider>
          </Box>

          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="unit-select-label">{getLabel("TXT_UNIT")}</InputLabel>
            <Select
              disabled={isShowInfo}
              labelId="unit-select-label"
              id="unit-select"
              label={getLabel("TXT_UNIT")}
              value={unit}
              onChange={handleChangeUnit}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_UNIT")}</MenuItem>
              {totalUnitList.map(unit => (
                <MenuItem value={unit.unitId} key={unit.unitId}>
                  {unit.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="department-select-label">{getLabel("TXT_DEPARTMENT")}</InputLabel>
            <Select
              disabled={isShowInfo || unit === ""}
              labelId="department-select-label"
              id="department-select"
              label={getLabel("TXT_DEPARTMENT")}
              value={department}
              onChange={handleChangeDepartment}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_DEPARTMENT")}</MenuItem>
              {departments.map(department => (
                <MenuItem value={department.departmentId} key={department.departmentId}>
                  {department.departmentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.infoField} fullWidth>
            <InputLabel id="position-select-label">{getLabel("TXT_POSITION")}</InputLabel>
            <Select
              disabled={isShowInfo}
              labelId="position-select-label"
              id="position-select"
              label={getLabel("TXT_POSITION")}
              value={position}
              onChange={handleChangePosition}
            >
              <MenuItem value={""}>{getLabel("TXT_CHOOSE_POSITION")}</MenuItem>
              {positions.map(position => (
                <MenuItem value={position.positionId} key={position.positionId}>
                  {position.positionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isCreate === false && (
            <>
              <TextField
                label={userDetail.activeStatus}
                fullWidth
                className={classes.infoField}
                value={activeStatus}
                InputProps={{
                  disabled: true,
                  classes: { input: classes.infoValue },
                }}
              />
              <TextField
                label={userDetail.joinStatus}
                fullWidth
                value={joinStatus}
                className={classes.infoField}
                InputProps={{
                  disabled: true,
                  classes: { input: classes.infoValue },
                }}
              />
            </>
          )}
        </DialogContent>
        {isEdit && (
          <DialogContent>
            <Button
              variant="contained"
              fullWidth
              onClick={isCreate ? handleCreateUser : handleEditUser}
              className={classes.editButton}
              disabled={email === "" || phone === "" || name === ""}
            >
              {getLabel(isCreate ? LangConstant.L_ADD_BUTTON : LangConstant.L_UPDATE)}
            </Button>
          </DialogContent>
        )}
      </Dialog>
      <Dialog open={Boolean(dialogContent)} onClose={handleCloseNotification}>
        <DialogContent>
          <DialogContentText className={classes.notificationContent}>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotification}>{getLabel(LangConstant.L_OK)}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UserInfoDialog;

UserInfoDialog.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
};

UserInfoDialog.defaultProps = {
  onClose: () => {},
};

const useStyles = makeStyles(() => ({
  component: {
    backgroundColor: "#FFFFFF",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },

  titleName: {
    fontSize: "24px",
    fontWeight: 700,
  },

  userContent: {
    margin: "0px",
    height: "500px",
    overflowY: "scroll",
  },

  closeButton: {},

  infoField: {
    margin: "10px 0px",
  },

  fieldNotEdit: {
    margin: "10px 0px",
    backgroundColor: "#F0F0F0",
    cursor: "default",
  },

  infoValue: {
    fontSize: 16,
    padding: 15,
  },

  editButton: {
    padding: 10,
  },

  notificationContent: {
    color: "#000000",
    marginTop: 10,
  },

  datePicker: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
